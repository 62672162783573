<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="cd-headline"
        >Populations by Race:</div>
      </v-col>
    </v-row>
    <v-flex d-flex xs12 v-if="year >= 2020">
      <v-card title flat class="chart-border fixed-height">
        <div style="min-height: 550px;">
          <bar-chart aria-hidden="true" :data="datacollection"  v-if="hasData"
          :options="chartOptions2020" :chartHeight="550"
           ariaLabel="Populations by Race"></bar-chart>
        </div>
         <table v-if="hasData" class="sr-only">
          <thead>
            <tr>
              <th>Race</th>
              <th>Completer Percentage</th>
              <th>P-12 Prcentage</th>
              <th>Workforce Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in p12.labels" :key="r">
              <td>{{ r }}</td>
              <td>{{ datacollection.datasets[0].data[i] }}%</td>
              <td>{{ datacollection.datasets[1].data[i] }}%</td>
              <td>{{ datacollection.datasets[2].data[i] }}%</td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </v-flex>
    <v-flex d-flex xs12 v-else>
      <v-flex d-flex xs12 md6>
        <v-card tile flat class="chart-border">
          <span title="Certified Completers Chart" class="chart-title">Certified Completers:</span>
          <div style="min-height: 380px;">
            <bar-chart  v-if="hasData" aria-hidden="true"
            :data="datacollection" :options="chartOptionsTwo"
            :chartHeight="380" ariaLabel="Certified Completers"></bar-chart>
          </div>
          <table v-if="hasData" class="sr-only">
            <thead>
              <tr>
                <th>Race</th>
                <th>Completer Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in p12.labels" :key="r">
                <td>{{ r }}</td>
                <td>{{ datacollection.datasets[0].data[i] }}%</td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 md6>
          <v-card tile flat class="chart-border">
              <span :title="'State ' + startingSchoolYear +
               ' Student P – 12 and ' + workingSchoolYear +
                ' Teacher Workforce Populations Chart'"
                 class="chart-title">State  {{ startingSchoolYear }}
                   Student P – 12 and {{ workingSchoolYear }} Teacher Workforce Populations:</span>
              <div style="min-height: 380px;margin-right: 20px">
                <bar-chart aria-hidden="true"
                :data="datacollectionworkforce"
                :options="chartOptions" :chartHeight="380" :ariaLabel="'State ' +
                  startingSchoolYear + ' Student P – 12 and ' + workingSchoolYear +
                  ' Teacher Workforce Populations Chart'"></bar-chart>
              </div>
              <!-- <table v-if="hasData" class="sr-only"> -->
              <table class="sr-only">
                <thead>
                  <tr>
                    <th>Race</th>
                    <th>P-12 Prcentage</th>
                    <th>Workforce Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in p12.labels" :key="r">
                    <td>{{ r }}</td>
                    <td>{{ datacollectionworkforce.datasets[0].data[i] }}%</td>
                    <td>{{ datacollectionworkforce.datasets[1].data[i] }}%</td>
                  </tr>
                </tbody>
              </table>
          </v-card>
      </v-flex>
    </v-flex>
  </v-container>
</template>

<script>
import BarChart from '../BarChart.vue';
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'CDRaceComponent',
  components: {
    BarChart,
  },
  props: { provider: {}, program: {}, year: {} },
  mixins: [NumberFormatMixin],
  computed: {
    races() {
      return this.$store.getters.race(this.provider, this.program, this.year);
    },
    p12() {
      return this.$store.getters.p12(this.year);
    },
    workforce() {
      return this.$store.getters.workforce(this.year);
    },
    startingSchoolYear() {
      return `${this.year - 2}-${this.year - 1}`;
    },
    workingSchoolYear() {
      return `${this.year - 1}-${this.year}`;
    },
    hasData() {
      if (!this.datacollection || !this.datacollection.labels
       || this.datacollection.labels.length === 0) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      datacollection: {},
      datacollectionworkforce: {},
      chartOptions2020: {},
      items: [
        {
          icon: 'fas fa-graduation-cap cd',
          color: 'cd title-size',
          title: 'Completer Demographics',
          to: '/completer-demographics',
          subtitle: 'Demographic information for candidates who completed a program at this institution and earned teacher certification in New Jersey.',
        },
      ],
    };
  },
  mounted() {
    if (this.year >= 2020) {
      this.fillCombinedData();
    } else {
      this.fillData();
      this.fillDataP12();
    }
    console.log('CDRace Mounted');
  },
  methods: {
    combineDataSets() {
      const dataSets = [
        {
          label: 'Completers',
          backgroundColor: '#303030',
          data: [],
        },
        {
          label: 'P-12',
          backgroundColor: '#808080',
          data: [],
        },
        {
          label: 'Workforce',
          backgroundColor: '#A9A9A9',
          data: [],
        },
      ];
      this.p12.labels.forEach((item) => {
        if (this.races.labels.indexOf(item) >= 0) {
          dataSets[0].data.push(this.races.data[this.races.labels.indexOf(item)]);
        } else {
          dataSets[0].data.push(0.0);
        }
        dataSets[1].data.push(this.p12.data[this.p12.labels.indexOf(item)]);
        dataSets[2].data.push(this.workforce.data[this.p12.labels.indexOf(item)]);
      });
      return dataSets;
    },
    fillCombinedData() {
      this.datacollection = {
        labels: this.p12.labels,
        datasets: this.combineDataSets(),
      };
      this.chartOptions2020 = {
        plugins: {
          datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            formatter(value, context) {
              return `${context.dataset.label}, ${value === undefined || Number.isNaN(value) ? 'NA' : value.toFixed(1)}%`;
            },
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += `: ${this.percentOrBlank(tooltipItem.xLabel)}%`;
              }
              return label;
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: false, // disables all animations
        animations: {
          colors: false, // disables animation defined by the collection of 'colors' properties
          x: false, // disables animation defined by the 'x' property
        },
        transitions: {
          active: {
            animation: { duration: 0 },
          },
        }, // disables the animation for 'active' mode
        indexAxis: 'y',
        barPercentage: 0.9,
        layout: {
          padding: {
            left: 0,
            right: 150,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              min: 0,
              max: 120,
            },
            scaleLabel: {
              display: true,
              labelString: '', // "Percentage"
            },
            title: {
              align: 'end',
              display: true,
            },
          },

          y: {
            grid: {
              display: false,
            },
            ticks: {
              // display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              color: 'gray',
              font: {
                size: 12,
              },
            },
            title: {
              align: 'end',
              display: true,
            },
          },
        },
        // scales: {
        //   xAxes: [{
        //     gridLines: {
        //       display: false,
        //     },
        //     ticks: {
        //       beginAtZero: true,
        //       min: 0,
        //       max: 120,
        //       // eslint-disable-next-line no-unused-vars
        //       callback(value) { return ''; },
        //     },
        //     scaleLabel: {
        //       display: true,
        //       labelString: '', // "Percentage"
        //     },
        //   }],
        //   yAxes: [{
        //     barThickness: 15,
        //   }],
        // },
      };
    },
    fillData() {
      this.datacollection = {
        labels: this.races.labels,
        datasets: [
          {
            label: 'Completers',
            backgroundColor: '#303030',
            data: this.races.data,
          },
        ],
      };
      this.chartOptionsTwo = {
        plugins: {
          datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            // eslint-disable-next-line no-unused-vars
            formatter(value, context) {
              return value === undefined || Number.isNaN(value)
                ? 'NA' : `${value.toFixed(1)}%`;
            },
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += `: ${this.percentOrBlank(tooltipItem.xLabel)}%`;
              }
              return label;
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: false, // disables all animations
        animations: {
          colors: false, // disables animation defined by the collection of 'colors' properties
          x: false, // disables animation defined by the 'x' property
        },
        transitions: {
          active: {
            animation: { duration: 0 },
          },
        }, // disables the animation for 'active' mode
        indexAxis: 'y',
        barPercentage: 0.9,
        layout: {
          padding: {
            left: 0,
            right: 150,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              min: 0,
              max: 120,
            },
            scaleLabel: {
              display: true,
              labelString: '', // "Percentage"
            },
            title: {
              align: 'end',
              display: true,
            },
          },
          // y: {
          // barThickness: 35,
          //   },
        },
        // scales: {
        //   xAxes: [{
        //     ticks: {
        //       beginAtZero: true,
        //       min: 0,
        //       max: 120,
        //       // eslint-disable-next-line no-unused-vars
        //       callback(value) { ''; },
        //     },
        //     scaleLabel: {
        //       display: true,
        //       labelString: '', // "Percentage"
        //     },
        //   }],
        //   yAxes: [{
        //     barThickness: 35,
        //   }],
        // },
      };
    },
    fillDataP12() {
      this.datacollectionworkforce = {
        labels: this.p12.labels,
        datasets: [
          {
            label: 'P-12',
            backgroundColor: '#808080',
            data: this.p12.data,
          },
          {
            label: 'Workforce',
            backgroundColor: '#A9A9A9',
            data: this.workforce.data,
          },
        ],
      };
      this.chartOptions = {
        plugins: {
          datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            formatter(value, context) {
              return `${context.dataset.label}, ${value === undefined || Number.isNaN(value) ? 'NA' : value.toFixed(1)}%`;
            },
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += `: ${this.percentOrBlank(tooltipItem.xLabel)}%`;
              }
              return label;
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: false, // disables all animations
        animations: {
          colors: false, // disables animation defined by the collection of 'colors' properties
          x: false, // disables animation defined by the 'x' property
        },
        transitions: {
          active: {
            animation: { duration: 0 },
          },
        }, // disables the animation for 'active' mode
        indexAxis: 'y',
        barPercentage: 0.9,
        layout: {
          padding: {
            left: 0,
            right: 150,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              min: 0,
              max: 120,
            },
            scaleLabel: {
              display: true,
              labelString: '', // "Percentage"
            },
            title: {
              align: 'end',
              display: true,
            },
          },
          // y: {
          // barThickness: 35,
          //   },
        },
      };
    },
  },
};
</script>

<style scoped>
  .headline{
      color: #114d72;
  }

</style>

<style>
  .chart-border{
      border: 1pt solid #DCDCDC;
      margin: 20px 10px;
      padding: 20px;
      text-align: center;
      width: 95%;
      border-radius: 0px!important;
      margin-left: 25px!important;
  }
  .fixed-height {
    height: 550px;
  }
  .small {
      max-width: 300px;
      margin:  15px auto;
  }
  .chart-title{
      font-size: 1.4em;
      text-align: center;

  }
  .centered{
      margin-left: 40%;
      float: left;
      height: 100px;
  }
  .gender{
      height: 40px;
  }
  .female{
      padding: 15px 300px;
      background: #696969;
      color: white;
      font-weight: bold;
      border: 1px solid #696969;
  }
  .male{
      padding: 15px 60px;
      background: #ffffff;
      border: 1px solid #696969;
      margin: 0;
      font-weight: bold;
      position: relative;
      right: 4px;
  }
  .unknown{
      font-weight: bold;
  }

</style>
