import Vue from 'vue';
import Vuex from 'vuex';
import Cookie from 'js-cookie';

import getters from './getters';

Vue.use(Vuex);

function getToken() {
  if (process.env.VUE_APP_USE_AUTHENTICATION === 'NO') {
    return {};
  }

  return Cookie.get('token');
}

export default new Vuex.Store({
  state: {
    error: '',
    token: getToken(),
    viewport: { height: 0, widht: 0 },
  },
  getters,
  mutations: {
    loginPost(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
    },
    viewportSize(state, size) {
      state.viewport = size;
    },
  },
  actions: {
    setLoginToken({ commit }, userToken) {
      commit('loginPost', userToken);
      Cookie.set('token', userToken, { expires: 3600 });
    },
    logout({ commit }) {
      Cookie.remove('token');
      commit('logout');
      // this.$router.push('/login');
    },
  },
  modules: {
    getters,
  },
});
