import { render, staticRenderFns } from "./FTStateComponent.vue?vue&type=template&id=13a8a828&scoped=true&"
import script from "./FTStateComponent.vue?vue&type=script&lang=js&"
export * from "./FTStateComponent.vue?vue&type=script&lang=js&"
import style0 from "./FTStateComponent.vue?vue&type=style&index=0&id=13a8a828&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a8a828",
  null
  
)

export default component.exports