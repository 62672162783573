<template>
  <div>
    <v-navigation-drawer width="95%" app absolute clipped v-model="drawer"
      class="mobile-nav drawer">
      <v-list dense>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('Home')" text color="#000000"
              :to="`/home/${year}/${provider}/${program}`">
              <i class="fas fa-home"></i>
              <span class="menu-link"  title="Home">Home</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('ProviderProfile')" text color="#A5121B"
              :to="`/provider-profile/${year}/${provider}/${program}`">
              <i class="fas fa-landmark"></i>
              <span class="menu-link"></span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('CompleterDemographics')" text color="#114d72"
              :to="`/completer-demographics/${year}/${provider}/${program}`">
              <i class="fas fa-graduation-cap"></i>
              <span class="menu-link">Completer Demographics</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('CertificationAssessments')" text color="#60521B"
              :to="`/certification-assessments/${year}/${provider}/${program}`">
              <i class="fas fa-chart-line"></i>
              <span class="menu-link">Certification Assessments</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('FullTimeEmployment')" text color="#952E0F"
              :to="`/full-time-employment/${year}/${provider}/${program}`">
              <i class="fas fa-handshake"></i>
              <span class="menu-link">FullTimeEmployment</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('EvaluationResults')" text color="#006100"
              :to="`/evaluation-results/${year}/${provider}/${program}`">
              <i class="fas fa-clipboard"></i>
              <span class="menu-link">Evaluation Results</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :class="isActiveLink('Glossary')" text color="#000000"
              :to="`/glossary/${year}/${provider}/${program}`">
              <i class="fas fa-clipboard"></i>
              <span class="menu-link">Glossary</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" class="page-header" clipped-left elevation="0">
      <v-toolbar-items class="full-nav">
        <v-btn :class="isActiveLink('Home')"
          color="#000000" :to="`/home/${year}/${provider}/${program}`" text title="Home">
          <i class="fas fa-home"></i><span class="menu-link">Home</span>
        </v-btn>
        <v-btn :class="isActiveLink('ProviderProfile')"  title="Provider Profile"
          color="#A5121B" :to="`/provider-profile/${year}/${provider}/${program}`" text>
          <i class="fas fa-landmark"></i><span class="menu-link">Provider Profile</span>
        </v-btn>
        <v-btn :class="isActiveLink('CompleterDemographics')" title="Completer Demographics"
          color="#114d72"
          :to="'/completer-demographics/' + year + '/' + provider + '/' + program" text>
          <i class="fas fa-graduation-cap"></i><span class="menu-link">Completer Demographics</span>
        </v-btn>
        <v-btn :class="isActiveLink('CertificationAssessments')" title="Certification Assessments"
          color="#60521B"
          :to="'/certification-assessments/' + year + '/' + provider + '/' + program" text>
          <i class="fas fa-chart-line"></i><span class="menu-link">Certification Assessments</span>
        </v-btn>
        <v-btn :class="isActiveLink('FullTimeEmployment')" title="Employment Outcomes"
          color="#952E0F"
          :to="'/full-time-employment/' + year + '/' + provider + '/' + program" text>
          <i class="fas fa-handshake"></i><span class="menu-link">Employment Outcomes </span>
        </v-btn>
        <v-btn :class="isActiveLink('EvaluationResults')" title="Evaluation Results"
          color="#006100"
          :to="'/evaluation-results/' + year + '/' + provider + '/' + program" text>
          <i class="fas fa-clipboard-list"></i><span class="menu-link">Evaluation Results</span>
        </v-btn>
        <v-btn :class="isActiveLink('Glossary')" title="Glossary"
          color="000000" :to="'/glossary/' + year + '/' + provider + '/' + program" text>
          <i class="fas fa-asterisk"></i><span class="menu-link">Glossary</span>
        </v-btn>
      </v-toolbar-items>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mobile-nav"></v-app-bar-nav-icon>
      <v-toolbar-title class="mobile-nav">NJ PREP Reports</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  props: {
    year: {
      type: String,
    },
    provider: {
      type: String,
    },
    program: {
      type: String,
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
  },
  methods: {
    isActiveLink(routeName) {
      switch (this.$route.name) {
        case routeName:
          return 'v-btn--active';
        default:
          return '';
      }
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped>
.menu-link{
  margin-left: 10px;
}
.fas{
  font-size: 1.4rem;
  float: left;
  margin-top: 0px;
  margin-left: 0px;
  width: 20px;
}
.page-header >>> .v-btn {
    padding: 0 .5%;
}
.mobile-nav {
    display: none;
    width: 100%;
}
@media only screen and (max-width: 1450px) {
  .full-nav {
    display: inline;
    width: 650px;
  }
  .menu-link {
    display: none;
  }
  .v-btn--active .menu-link {
    display: inline;
  }
}
@media only screen and (max-width: 600px) {
  .v-btn--active .menu-link {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  .full-nav {
    display: none;
  }
  .mobile-nav {
    display: inline;
  }
  .mobile-nav .menu-link {
    display: inline;
  }
  .mobile-nav .v-btn {
    width: 100%;
  }
  .mobile-nav >>> .v-btn__content {
    justify-content: start;
  }
}
</style>
<style>
@media only screen and (max-width: 1400px){
  header.v-toolbar {
    position: unset;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
.v-toolbar__content {
    padding: 4px 0 !important;
}
</style>
