<template>
  <div>
  <div v-for="(item) in items"  :key="item.title" :class="item.colorbar"></div>
  <v-layout>
      <v-flex xs12 sm12 md12>
          <v-card>
              <v-list three-line subheader>
                  <template v-for="(item) in items">
                      <span :key="`${item.title}_icon`"><i :class="item.icon"></i></span>
                      <v-list-item :key="`${item.title}_li`">
                        <v-list-item-content>
                          <v-list-item-title :class="item.color">
                            {{ item.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.subtitle }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                  </template>
              </v-list>

          </v-card>
      </v-flex>
  </v-layout >
  </div>
</template>

<script>
export default {
  name: 'PageTitleColorBar',
  props: { items: {} },
};
</script>

<style scoped>
  .v-card{
        box-shadow: none !important;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0px;
  }
  .fas{
      font-size: 3rem;
      float: left;
      margin-top: 15px;
      margin-left: 15px;
  }
  .pt-color-bar{
      height: 20px;
      background: #952E0F;
      width: 100%;
  }
  .pp-color-bar{
      height: 20px;
      background: #A5121B;
      width: 100%;
  }
  .er-color-bar{
      height: 20px;
      background: #006100;
      width: 100%;
  }
  .cd-color-bar{
      height: 20px;
      background: #114d72;
      width: 100%;
  }
  .ca-color-bar{
      height: 20px;
      background: #60521B;
      width: 100%;
  }
  .gl-color-bar{
      height: 20px;
      background: black;
      width: 100%;
  }
  .title-size{
      overflow: unset;
      /* margin-bottom: 5px; */
      font-size: 1.5rem;
  }
  @media only screen and (max-width: 500px){
      .title-size{
          font-size: 1.5rem;
      }
  }
  @media only print{
      .title-size{
          font-size: 1.5rem;
      }
  }
</style>
