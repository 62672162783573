<template>
  <div  style="margin-top:10px!important;">
  <v-container class="main-container" fluid>
    <v-row style="align-items: center;" >
      <v-col class="pa-4 col-sm-4 col-md-2" >
        <v-card flat color="#2c7fc3" class="pa-2">
          <a href="/" v-if="!disableLinks">
            <img class="doe-image" alt="NJDOE" src="@/assets/NJDOE.png"/></a>
            <span v-else><img class="doe-image" alt="NJDOE" src="@/assets/NJDOE.png"/></span>
        </v-card>
      </v-col>
      <v-col  class="col-sm-6 col-md-6">
        <v-card dark tile flat color="#2c7fc3"  >
          <v-card-text>
            <p>New Jersey Department of Education</p>
            <p>{{ reportTitle }}</p>
            <br/>
            <h2>{{ headerData.ProviderName }}</h2>
            <p v-if="showCourseWorkLevel">{{ courseWorkLevel }}</p>
            <p>{{ certificateType }}</p>
            <p>{{ headerData.ProgramName }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  class="col-sm-2 col-md-4 pr-0"
       style="padding-right: 0px!important;" v-if="window.width > 500">
       <v-row justify="center" dense>
        <v-col cols="auto">
          <a title="PDF Link" class="pdf-link"
              aria-label="Link to download the pdf version of this report"
              :href="'/report-pdf/' + year + '/' + provider + '-' + pdfProgramName + '.pdf'">
              <i class="fas fa-file-pdf pdf" style="float: left"></i>
            </a>
        </v-col>
        <v-col>
          <div class="year-text">{{ year }}</div>
        </v-col>
       </v-row>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {
  name: 'MainEppprHeader',
  props: {
    provider: {}, program: {}, year: {}, disableLinks: {},
  },
  mounted() {
    console.log('Main Menu Mounted');
  },
  computed: {
    headerData() {
      return this.$store.getters.header(this.provider, this.program, this.year);
    },
    reportTitle() {
      if (this.year >= 2020) {
        return 'Performance Reports for Educator Preparation';
      }
      return 'Educator Preparation Provider Performance Reports';
    },
    certificateType() {
      if (this.year >= 2020) {
        if (this.provider === 'NJ CE Providers' || this.provider.endsWith('- CE')) {
          return 'Certificate of Eligibility';
        }
      }
      return 'Certificate of Eligibility with Advanced Standing';
    },
    courseWorkLevel() {
      if (this.year >= 2020) {
        if (this.headerData.ProviderName === 'NJ CE Providers') {
          return '';
        }
      }
      return this.headerData.ProviderCourseWorkLevel;
    },
    showCourseWorkLevel() {
      if (this.year >= 2022) {
        if (this.headerData.ProviderCourseWorkLevel === 'NA') {
          return false;
        }
      }
      return true;
    },
    pdfProgramName() {
      return this.program.replace(':', '');
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
};
</script>

<style scoped>
.main-container{
  /* background: #2c7fc3; */
  background: linear-gradient(to bottom,
    #dcdcdc 0.02in,
      #2c7fc3 0.02in 100%);
}
p {
  margin: 0px;
}
.container{
  padding: 0px;
}
.v-card{
  position: unset;
}
.pdf{
  font-size: 3rem;
  float: right;
  margin-right: 30px;
  margin-top: 0;
  color: white;
}
.pdf:hover{
  color: #000000;
}
</style>
<style>
.doe-image{
  height: 95%;
  /* padding-left: 20px; */
  padding: 20px;
  width: 95%;
  /* position: relative;
   */
}
nav.v-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
}
@media only screen and (max-width: 500px){
  img{
    display: none;
  }
}
.pdf-link {
  margin-top: 0px;
  float: right;
}
@media print{
  .pdf-link {
    display: none;
  }
}
div.year-text {
  font-size: 2em;
  color: white;
}

</style>
<style scoped>
  .v-card__text{
    color: rgba(255, 255, 255, 1)!important;
  }
  .doe-image{
    max-width: 200px;
    padding: 0px;
  }
</style>
