<template>
  <div
    id="e3"
    style="margin: auto;"
    class="grey lighten-3">
    <v-app light>
      <v-toolbar color="#002b5c">
        <v-toolbar-title class="white--text" v-text="title"></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-content>
        <v-container>
          <h3>Loging you out. Please wait</h3>
        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>

export default {
  data: () => ({
    token: null,
    authState: 'SignIn',
    auth: {},
    title: 'Public Consulting Group',
  }),
  middleware: 'notAuthenticated',
  beforeMount() {
  },
  mounted() {
    this.$store.dispatch('logout');
    this.$router.push('/login');
  },

};
</script>

<style scoped>
#e3{
  width: 50%;
}
.v-text-field, .support-info {
  margin: 5px 15px;
}
.v-btn {
  margin: 5px 15px;
}
.v-toolbar__title {
  margin: 5px 15px;
}
</style>
