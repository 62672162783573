<template>
  <v-card>
      <v-card-title class="ft-headline">Employment by Certification Count:</v-card-title>
      <v-card-text>
          <template>
              <v-data-table
                      :headers="headersCount"
                      :items="counts"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1"

              >
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header" v-on="on">
                            {{ props.header.text }}
                          </span>
                      </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.EndorsementCount"></td>
                      <td v-html="props.item.ObtainedNJCEAS" class="text-xs-center"></td>
                      <td v-html="props.item.EmployedasofOct15" class="text-xs-center"></td>
                      <td v-html="props.item.PercentageEmployedTeachers"
                       class="text-xs-center percent"></td>
                    </tr>
                  </template>
              </v-data-table>
          </template>
      </v-card-text>
  </v-card>
</template>

<script>

import DateFormatMixin from '../../mixins/date-format-mixins';
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'FTCountComponent',
  props: { provider: {}, program: {}, year: {} },
  mixins: [DateFormatMixin, NumberFormatMixin],
  computed: {
    counts() {
      const countList = this.$store.getters.counts(this.provider, this.program, this.year);
      countList.forEach((count) => {
        const c = count;
        c.PercentageEmployedTeachers = this.percentOrBlank(count.PercentageEmployedTeachers);
        // eslint-disable-next-line max-len
        c.PercentageEmployedTeachersState = this.percentOrBlank(count.PercentageEmployedTeachersState);
      });
      return countList;
    },
    headersCount() {
      return [
        { text: 'Category', value: 'EndorsementCount' },
        { text: 'Count of Certified Individuals', value: 'ObtainedNJCEAS' },
        { text: `Employed as of October 15, ${this.currentSchoolYearStart(this.year)}`, value: 'EmployedasofOct15' },
        { text: 'Percent Employed as Teachers', value: 'PercentageEmployedTeachers' },
      ];
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
  .percent:after{
      content: "%";
  }
</style>
