<template>
  <v-card>
    <v-card-title class="ft-headline">{{ prior2Year }} Persistence <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on"><sup>7</sup></span>
      </template>
        <span class="tooltip-text">Candidates who continued with their employment at the school,
            district, or state level from one year to the next.</span></v-tooltip>&nbsp;Trend:
    </v-card-title>
    <v-container fluid >
      <v-row class="pa-3">
        <v-col class="border">
          <v-row>
            <v-col :class="{ 'sm4 offset-sm1': year >= 2020, 'sm6': year < 2020 }" >
              <div class="grad">
                    <div class="icon-wrapper">
                        <i class="fa fa-graduation-cap cap" ></i>
                    </div>
                    <div class="center" v-for="trend in trends" :key="trend.ProgramName">
                        <p class="pdf-p">Of the <strong>{{ trend.TotalCompletersPrior3Year }}
                        </strong> <br>
                            Certified Completers<br>
                            from the <strong>{{ prior2Year }} SY</strong></p>
                    </div>
                </div>
            </v-col>
            <v-col :class="{ 'sm2': year >= 2020, 'd-none': year < 2020 }" >
              <div class="arrow">
                    <div class="icon-wrapper">
                        <i class="fas fa-arrow-right"></i>
                    </div>
                </div>
            </v-col>
            <v-col :class="{ 'sm4': year >= 2020, 'sm6': year < 2020 }" >
              <div class="handshake">
                    <div class="icon-wrapper">
                        <i class="fa fa-handshake hand"></i>
                    </div>
                    <div class="center" v-for="trend in trends" :key="trend.ProgramName">
                        <p class="pdf-p"><strong>{{ trend.TotalEmployedPrior2Year }}
                            {{ employedPercent(trend) }}</strong> <br>
                            were employed as of <br>
                            the <strong>{{ priorYear }} SY</strong></p>
                    </div>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="center level" v-for="trend in trends" :key="trend.ProgramName">
                        <p class="pdf-p"> <strong>School Level:</strong> <br/>
                            <strong>{{ trend.SchoolRetentionCountPriorYear }}
                                ({{ percentOrBlank(trend.SchoolRetentionPercentagePriorYear) }}%)
                              </strong> of those employed <br/>
                            completers maintained <br/>
                            employment during the <strong>{{ currenYearStart }}- <br/>
                                {{ currentYearEnd }} SY</strong> with a median salary of <br/>
                            <strong>
                              ${{ trend.SchoolMedianSalaryPriorYear ?
                                trend.SchoolMedianSalaryPriorYear.toLocaleString() : 'NA' }}
                            </strong></p>
                    </div>
            </v-col>
            <v-col>
              <div class="center level" v-for="trend in trends" :key="trend.ProgramName">
                        <p class="pdf-p"><strong>District Level:</strong> <br/>
                            <strong>{{ trend.DistrictRetentionCountPriorYear }}
                                ({{ percentOrBlank(trend.DistrictRetentionPercentagePriorYear) }}%)
                              </strong> of those employed <br/>
                            completers maintained <br/>
                            employment during the <strong>{{ currenYearStart }}- <br/>
                                {{ currentYearEnd }} SY</strong> with a median salary of <br/>
                            <strong>${{ trend.DistrictMedianSalaryPriorYear?
                              trend.DistrictMedianSalaryPriorYear.toLocaleString() : 'NA'  }}
                              </strong></p>
                    </div>
            </v-col>
            <v-col>
              <div class="center level" v-for="trend in trends" :key="trend.ProgramName">
                        <p class="pdf-p"><strong>State Level:</strong> <br/>
                            <strong>{{ trend.StateRetentionCountPriorYear }}
                                ({{ percentOrBlank(trend.StateRetentionPercentagePriorYear) }}%)
                              </strong> of those employed<br/>
                            completers maintained <br/>
                            employment during the <strong>{{ currenYearStart }}-<br/>
                                {{ currentYearEnd }} SY</strong> with a median salary of<br/>
                            <strong>${{ trend.StateMedianSalaryPriorYear ?
                              trend.StateMedianSalaryPriorYear.toLocaleString() : 'NA' }}
                              </strong></p>
                    </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DateFormatMixin from '../../mixins/date-format-mixins';
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'FTTrendTopComponent',
  props: { provider: {}, program: {}, year: {} },
  mixins: [NumberFormatMixin, DateFormatMixin],
  computed: {
    trends() {
      return this.$store.getters.trends(this.provider, this.program, this.year);
    },
  },
  methods: {
    employedPercent(trend) {
      if (this.year < 2020) {
        return '';
      }
      if (!trend || (!trend.TotalEmployedPrior2Year && trend.TotalEmployedPrior2Year !== 0)
      || !trend.TotalCompletersPrior3Year) {
        return '(NA%)';
      }
      return `(${Math.round((trend.TotalEmployedPrior2Year / trend.TotalCompletersPrior3Year) * 100)}%)`;
    },
  },
};
</script>

<style scoped>
  .center{
      text-align: center;
  }
  .icon-wrapper{
      clear: both;
  }
  .tooltip-text{
      font-size: 1rem;
  }
  .grad{
      text-align: center;
  }
  .handshake{
      text-align: center;
  }
  .arrow{
      text-align: center;
      margin: auto;
      font-size: 102px;
      color: #952E0F;
  }
  @media print {
      .arrow{
          font-size: 36pt;
      }
  }
  .trend-wrapper{
      height: 510px;
  }
  .border{
      border: solid 2px #696969;
      margin: 0 20px;
      padding: 20px;
  }
  .grad-handshake-wrapper{
      margin-left: 25%;
  }
  .box-grid{
      width: 100%;
      clear: both;
  }
  .level{
      float: left;
      /* width: 32%; */
      background: #DCDCDC;
      color: #696969;
      margin: 5px;
      padding: 10px;
  }
  p{
      font-size: 1.75rem;
  }
  .hand{
      font-size: 6rem;
      color: #c9c7c8;
  }
  .cap{
      font-size: 6rem;
      color: #c9c7c8;
  }
  .trend-headline{
      color: #952E0F;
      padding: 20px 0px 0 16px;
      font-size: 24px;
      font-weight: bold;
  }
</style>
