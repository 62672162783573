import { render, staticRenderFns } from "./FTCategoryComponent.vue?vue&type=template&id=3c3d4c72&scoped=true&"
import script from "./FTCategoryComponent.vue?vue&type=script&lang=js&"
export * from "./FTCategoryComponent.vue?vue&type=script&lang=js&"
import style0 from "./FTCategoryComponent.vue?vue&type=style&index=0&id=3c3d4c72&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c3d4c72",
  null
  
)

export default component.exports