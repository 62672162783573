<template>
  <div>
    <page-title-color-bar :items="items"></page-title-color-bar>
    <v-container class="mt-4" >
      <v-row>
        <v-col cols="12">
          <f-t-trend-top :provider="provider" :program="program" :year="year"></f-t-trend-top>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <f-t-trend-bottom :provider="provider" :program="program" :year="year"></f-t-trend-bottom>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <f-t-count :provider="provider" :program="program" :year="year" v-if="!isCe"></f-t-count>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <f-t-area :provider="provider" :program="program" :year="year"
            v-if="program === 'All Programs' && !isCe"></f-t-area>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <f-t-region :provider="provider" :program="program" :year="year"></f-t-region>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <f-t-classification :provider="provider" :program="program"
            :year="year" v-if="!isCe"></f-t-classification>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <f-t-category :provider="provider" :program="program" :year="year"
           v-if="!isCe"></f-t-category>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" v-if="!isCe && !isCeas">
          <f-t-provider :provider="provider" :year="year"></f-t-provider>
        </v-col>
        <v-col cols="6">
          <f-t-state :provider="provider" :year="year"></f-t-state>
        </v-col>
      </v-row>
  </v-container>
  <footnotes :items="notes"></footnotes>
  </div>
</template>

<script>
import PageTitleColorBar from '../../components/PageTitleColorBar.vue';

import FTCount from '../../components/eo/FTCountComponent.vue';
import FTArea from '../../components/eo/FTAreaComponent.vue';
import FTRegion from '../../components/eo/FTRegionComponent.vue';
import FTClassification from '../../components/eo/FTClassificationComponent.vue';
import FTTrendBottom from '../../components/eo/FTTrendBottomComponent.vue';
import FTTrendTop from '../../components/eo/FTTrendTopComponent.vue';
import FTCategory from '../../components/eo/FTCategoryComponent.vue';
import FTProvider from '../../components/eo/FTProviderComponent.vue';
import FTState from '../../components/eo/FTStateComponent.vue';
import footnotes from '../../components/FootnotesComponent.vue';

export default {
  components: {
    PageTitleColorBar,
    FTCount,
    FTArea,
    FTRegion,
    FTClassification,
    FTTrendBottom,
    FTTrendTop,
    FTCategory,
    FTProvider,
    FTState,
    footnotes,
    // eslint-disable-next-line vue/no-unused-components, no-undef
    // MainEppprHeader,
  },
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
  },
  computed: {
    glossaryLink() {
      return `/glossary/${this.$route.params.year}/${this.$route.params.provider}/${this.$route.params.program}`;
    },
    isCe() {
      const ce = 'NJ CE Providers';
      return this.provider === ce;
    },
    isCeas() {
      const ce = 'NJ CEAS Providers';
      return this.provider === ce;
    },
    notes() {
      let resultList = [];
      if (this.provider === 'NJ CE Providers' && this.program !== 'All Programs') {
        resultList = [
          { id: 7, note: '<sup>7</sup> Candidates who continued with their employment at the school, district, or state level from one year to the next.' },
          { id: 8, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. Salary data is suppressed if the average salary of Full Time Employed Teachers (FTE) in a region is less than $30,000 and the count of FTE Teachers is greater than 0. If the count of FTE Teachers is 0, then N/A will appear in the cell. For more information see the <a href="${this.glossaryLink}">glossary</a>.` },
          { id: 10, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about regions is available in the <a href="${this.glossaryLink}">glossary</a> and information about school classification is available on the NJDOE website.` },
        ];
      }
      if (this.provider === 'NJ CE Providers') {
        resultList = [
          { id: 7, note: '<sup>7</sup> Candidates who continued with their employment at the school, district, or state level from one year to the next.' },
          { id: 8, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. Salary data is suppressed if the average salary of Full Time Employed Teachers (FTE) in a region is less than $30,000 and the count of FTE Teachers is greater than 0. If the count of FTE Teachers is 0, then N/A will appear in the cell. For more information see the <a href="${this.glossaryLink}">glossary</a>.` },
          { id: 10, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about regions is available in the <a href="${this.glossaryLink}">glossary</a> and information about school classification is available on the NJDOE website.` },
          { id: 12, note: '* Note: The NJDOE collects employment and certication data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certicate. ' },
        ];
      }
      if (this.program === 'All Programs') {
        if (this.year >= 2020) {
          resultList = [
            { id: 7, note: '<sup>7</sup> Candidates who continued with their employment at the school, district, or state level from one year to the next.' },
            { id: 8, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. Salary data is suppressed if the average salary of Full Time Employed Teachers (FTE) in a region is less than $30,000 and the count of FTE Teachers is greater than 0. If the count of FTE Teachers is 0, then N/A will appear in the cell. For more information see the <a href="${this.glossaryLink}">glossary</a>.` },
            { id: 9, note: '* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate.' },
            { id: 10, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about regions is available in the <a href="${this.glossaryLink}">glossary</a> and information about school classification is available on the NJDOE website.` },
            { id: 11, note: '* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about school categories is available on the NJDOE website.' },
            { id: 12, note: '* Note: The NJDOE collects employment and certication data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certicate. ' },
          ];
        } else {
          resultList = [
            { id: 7, note: '<sup>7</sup> Candidates who continued with their employment at the school, district, or state level from one year to the next.' },
            { id: 8, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. Salary data is suppressed if the average salary of Full Time Employed Teachers (FTE) in a region is less than $30,000 and the count of FTE Teachers is greater than 0. If the count of FTE Teachers is 0, then N/A will appear in the cell. For more information see the <a href="${this.glossaryLink}">glossary</a>.` },
            { id: 10, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about regions is available in the <a href="${this.glossaryLink}">glossary</a> and information about school classification is available on the NJDOE website.` },
            { id: 12, note: '* Note: The NJDOE collects employment and certication data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certicate. ' },
          ];
        }
      } else {
        resultList = [
          { id: 7, note: '<sup>7</sup> Candidates who continued with their employment at the school, district, or state level from one year to the next.' },
          { id: 8, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. Salary data is suppressed if the average salary of Full Time Employed Teachers (FTE) in a region is less than $30,000 and the count of FTE Teachers is greater than 0. If the count of FTE Teachers is 0, then N/A will appear in the cell. For more information see the <a href="${this.glossaryLink}">glossary</a>.` },
          { id: 9, note: '* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate.' },
          { id: 10, note: `* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about regions is available in the <a href="${this.glossaryLink}">glossary</a> and information about school classification is available on the NJDOE website.` },
          { id: 11, note: '* Note: The NJDOE collects demographic and salary data through NJSMART and TCIS. A candidate may be included in multiple employment counts if they have earned more than one certificate. Information about school categories is available on the NJDOE website.' },
        ];
      }
      if (this.year >= 2020) {
        resultList.splice(
          1,
          0,
          { id: 13, note: '<sup>8</sup> Comprehensive Support and Improvement - Overall Low Performing: Schools with a summative score in the bottom 5% of Title I schools or schools with a four-year graduation rate of 67% or less' },
          { id: 14, note: '<sup>9</sup> Targeted Support and Improvement - Schools with one or more student group with a summative score that would be in the bottom 5% of Title I schools or schools with one or more students group that missed annual targets or standards for all indicators for two years in a row' },
        );
      }
      return resultList;
    },
    fullTimeTitle() {
      if (this.year >= 2020) {
        return 'Full Time Employment Outcomes and Compensation';
      }
      return 'Full Time Employment Outcomes';
    },
    fullTimeSubTitle() {
      if (this.year >= 2020) {
        return 'Data about certified program completer performance on licensure assessments.';
      }
      return 'Data about program completers from this institution  working in New Jersey public schools.';
    },
    items() {
      return [
        {
          icon: 'fas fa-handshake pt',
          colorbar: 'pt-color-bar',
          color: 'pt title-size',
          title: this.fullTimeTitle,
          to: '/full-time-employment',
          subtitle: this.fullTimeSubTitle,
        },
      ];
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
a{
text-decoration: none;
}
.pt{
color: #952E0F;
}
.container {
flex: 0;
padding: 0;
}
.v-card{
box-shadow: none !important;
}

</style>
<style>
tbody tr:nth-child(odd) {
background: #e0e0e0;
}
thead{
background: #a6a6a6;
}
.table-header {
color: #ffffff;
font-size: 1.5rem;
}
.pt{
color: #952E0F;
}
.container {
flex: 0;
padding: 0;
}
.ft-headline{
color: #952E0F;
font-size: 24px;
font-weight: 400;
line-height: 32px !important;
letter-spacing: normal !important;
font-family: 'Roboto', sans-serif !important;
}
.v-icon.fa-sort-up,
.v-icon.fa-sort-down {
font-size: 18px !important;
color: #2C7FC3 !important;
}
</style>
