<template>
  <v-container fluid v-if="hasData" style="width:95%;" class="border ft ft-state">
      <v-card tile flat style="min-height: 400px;" class="ft-chart-card">
          <v-card-title :title="graphTitle"
           class="chart-title ft-headline">{{ graphTitle }}</v-card-title>
          <v-card-subtitle title="The following table represents
           the count of unique completers by certification area in the five
            largest programs represented in this report"
             class="chart-subtitle">The following table represents the
              count of unique completers by certification area in the five
               largest programs represented in this report</v-card-subtitle>
               <div >
                  <bar-chart aria-hidden="true" :data="dataprovider"
                  :options="chartOptions" :chartId="'state'" :chartHeight="400"></bar-chart>
                </div>
          <table class="sr-only" :summary="graphTitle">
            <thead>
              <tr>
                <th>Provider</th>
                <th>Employment</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in dataprovider.labels" :key="r">
                <td>{{ r }}</td>
                <td>{{ dataprovider.datasets[0].data[i] }}</td>
              </tr>
            </tbody>
          </table>
      </v-card>
    </v-container>
</template>

<script>
import BarChart from '../BarChart.vue';

export default {
  name: 'FTStateComponent',
  components: {
    BarChart,
  },
  props: { provider: {}, year: {} },
  computed: {
    providerData() {
      const provider = this.$store.getters.providerStateData(this.provider, this.year);
      return provider;
    },
    graphTitle() {
      if (this.year > 2018) {
        if (this.provider.indexOf(' CEAS') >= 0) {
          return 'Employment for Largest Five NJ CEAS Programs';
        }
        return 'Employment for Largest Five NJ CE Programs';
      }
      return 'Employment for Largest Five NJ CEAS Programs';
    },
    hasData() {
      return this.providerData.data.filter((v) => v !== 0);
    },
    labels() {
      const self = this;
      return this.label.map((l) => {
        if (self.window.width > 1200) {
          return l.match(/.{1,40}[^ ]*/g);
        }
        return l.match(/.{1,20}[^ ]*/g);
      });
    },
    maxCount() {
      const count = Math.max.apply(null, this.providerData.data);
      return count + 0.2 * count;
    },
    dataprovider() {
      return {
        labels: this.providerData.labels,
        datasets: [
          {
            label: 'Provider',
            backgroundColor: '#303030',
            data: this.providerData.data,
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            font: {
              size: 11,
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: false, // disables all animations
        animations: {
          colors: false, // disables animation defined by the collection of 'colors' properties
          x: false, // disables animation defined by the 'x' property
        },
        transitions: {
          active: {
            animation: { duration: 0 },
          },
        }, // disables the animation for 'active' mode
        indexAxis: 'y',
        barPercentage: 0.4,
        layout: {
          padding: {
            left: 0,
            right: 30,
            top: 0,
            bottom: 10,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              color: 'black',
              font: {
                size: 10,
              },
            },
            title: {
              align: 'end',
              display: true,
            },
          },

          y: {
            grid: {
              display: false,
            },
            ticks: {
              // display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              color: 'black',
              font: {
                size: 10,
              },
            },
            title: {
              align: 'end',
              display: true,
            },
          },
        },
      };
    },
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      datastate: null,
      label: [],

    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>

.ft-chart-card{
  min-height: 350px;
}

.chart-subtitle{
padding: 0 20px;
display: block;
}
.border{
  border: 1pt solid #DCDCDC;
   margin: 10px 10px;
   padding: 20px;
  /*
  width: 100%; */
}
canvas{
  width: 90%!important;
}
.v-card__subtitle {
    margin-top: 0px;
}
</style>
