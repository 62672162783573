<template>
  <v-container fluid v-if="hasData" style="width:95%;" class="border  ft ft-state">
    <!-- <v-row>
      <v-col> -->
        <v-card tile flat class="ft-chart-card">
          <v-card-title  title="Employment for Largest Five Programs: Provider"
          class="chart-title ft-headline">
          Employment for Largest Five Programs: Provider</v-card-title>
          <div>
            <bar-chart aria-hidden="true" :data="dataprovider"
            :options="chartOptions" :chartId="'provider'" :chartHeight="400"></bar-chart>
          </div>
          <table class="sr-only">
            <thead>
              <tr>
                <th>Provider</th>
                <th>Employment</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in dataprovider.labels" :key="i">
                <td>{{ r }}</td>
                <td>{{ dataprovider.datasets[0].data[i] }}</td>
              </tr>
            </tbody>
          </table>
      </v-card>
    <!-- </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import BarChart from '../BarChart.vue';

export default {
  name: 'FTProviderComponent',
  components: {
    BarChart,
  },
  props: { provider: {}, year: {} },
  computed: {
    providerData() {
      return this.$store.getters.providerData(this.provider, this.year);
    },
    hasData() {
      return this.providerData.data.filter((v) => v !== 0).length > 0;
    },
    labels() {
      const self = this;
      return this.providerData.labels.map((l) => {
        if (self.window.width > 1200) {
          return l.match(/.{1,40}[^ ]*/g);
        }
        return l.match(/.{1,20}[^ ]*/g);
      });
    },
    maxCount() {
      const count = Math.max.apply(null, this.providerData.data);
      return count + 0.2 * count;
    },
    dataprovider() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: 'Provider',
            backgroundColor: '#303030',
            data: this.providerData.data,
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          datalabels: {
            color: '#000000',
            anchor: 'end',
            align: 'end',
            font: {
              size: 11,
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: false, // disables all animations
        animations: {
          colors: false, // disables animation defined by the collection of 'colors' properties
          x: false, // disables animation defined by the 'x' property
        },
        transitions: {
          active: {
            animation: { duration: 0 },
          },
        }, // disables the animation for 'active' mode
        indexAxis: 'y',
        barPercentage: 0.4,
        layout: {
          padding: {
            left: 0,
            right: 30,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: this.maxCount,
              beginAtZero: true,
              color: 'black',
              font: {
                size: 10,
              },
            },
            title: {
              align: 'end',
              display: true,
            },
          },

          y: {
            grid: {
              display: false,
            },
            ticks: {
              // display: false,
              // maxTicksLimit: this.maxCount,
              beginAtZero: true,
              color: 'black',
              font: {
                size: 10,
              },
            },
            title: {
              align: 'end',
              display: true,
            },
          },
        },
      };
    },
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
.ft-chart-card{
  min-height: 350px;
}
.border{
  border: 1pt solid #DCDCDC;
   margin: 10px 10px;
   padding: 20px;
  /*
  width: 100%; */
}
canvas{
  width: 90%!important;
}
</style>
