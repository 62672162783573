/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import LandingView from '../views/LandingView.vue';
import ReportView from '../views/ReportView.vue';
import PrintView from '../views/PrintView.vue';
import LoginView from '../views/LoginView.vue';
import LogoutView from '../views/LogoutView.vue';

import Home from '../views/ReportComponents/HomeComponent.vue';
import PP from '../views/ReportComponents/ProviderProfile.vue';
import CD from '../views/ReportComponents/CompleterDemographics.vue';
import CA from '../views/ReportComponents/CertificationAssessments.vue';
import EO from '../views/ReportComponents/EmploymentOutcomes.vue';
import ER from '../views/ReportComponents/EvaluationResults.vue';
import GY from '../views/ReportComponents/GlossaryView.vue';

Vue.use(VueRouter);

function doAuth(to, from, next) {
  const isAuthenticated = store.getters.auth;
  if (!isAuthenticated) {
    next({ name: 'login', query: { redirect: to.fullPath } });
  } if (process.env.VUE_APP_SHOW_ONLY_ALL_PROGRAMS === 'YES'
  && to && to.params && to.params.program
  && to.params.program.length > 0
  && to.params.program !== 'All Programs'
  ) {
    // check if program name is present when only "All Programs" is available.
    // If yes, redirect them to home page
    next({ name: 'landing' });
  } else {
    next();
  }
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
  },
  {
    path: '/print/:year/:provider/:program',
    name: 'Print',
    component: PrintView,
  },
  {
    path: '/',
    name: 'landing',
    component: LandingView,
    beforeEnter: doAuth,
  },
  {
    path: '/',
    name: 'Report',
    component: ReportView,
    beforeEnter: doAuth,
    children: [
      {
        name: 'Home',
        path: 'home/:year/:provider/:program',
        component: Home,
      },
      {
        name: 'ProviderProfile',
        path: 'provider-profile/:year/:provider/:program',
        component: PP,
      },
      {
        name: 'CompleterDemographics',
        path: 'completer-demographics/:year/:provider/:program',
        component: CD,
      },
      {
        name: 'CertificationAssessments',
        path: 'certification-assessments/:year/:provider/:program',
        component: CA,
      },
      {
        name: 'EmploymentOutcomes',
        path: 'full-time-employment/:year/:provider/:program',
        component: EO,
      },
      {
        name: 'EvaluationResults',
        path: 'evaluation-results/:year/:provider/:program',
        component: ER,
      },
      {
        name: 'Glossary',
        path: 'glossary/:year/:provider/:program',
        component: GY,
      },
      {
        name: 'Default',
        path: 'home/:year/:provider/:program',
        component: Home,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
