<template>
          <v-container fluid class="center-scores">
            <v-row>
              <v-col cols="auto" style="padding-right: 0px;">
                  <div class="highly lg"></div>

              </v-col>
              <v-col cols="auto" style="padding-left: 0px;">
                <div class="range">
                      <span class="e-r-title" :class="{ 'text-upper': year < 2020 }">
                        Highly Effective</span>
                      <span class="score">Score: 3.50-4.00</span>
                  </div>
              </v-col>
              <v-col cols="auto" style="padding-right: 0px;">
                  <div class="effective lg"></div>
                </v-col>
                <v-col cols="auto" style="padding-left: 0px;">
                  <div class="range">
                      <span class="e-r-title" :class="{ 'text-upper': year < 2020 }">
                        Effective</span>
                      <span class="score">Score: 2.65-3.49</span>
                  </div>
                </v-col>
                <v-col cols="auto" style="padding-right: 0px;">
                  <div class="partially lg"></div>
                </v-col>
                <v-col cols="auto" style="padding-left: 0px;">
                  <div class="range">
                      <span class="e-r-title" :class="{ 'text-upper': year < 2020 }">
                        Partially Effective</span>
                      <span class="score">Score: 1.85-2.64</span>
                  </div>
                </v-col>
                <v-col cols="auto" style="padding-right: 0px;">
                  <div class="ineffective lg"></div>
                </v-col>
                <v-col cols="auto" style="padding-left: 0px;">
                  <div class="range">
                      <span class="e-r-title" :class="{ 'text-upper': year < 2020 }">
                        Ineffective</span>
                      <span class="score">Score: 1.00-1.84</span>
                  </div>
                </v-col>
                <v-col cols="auto" style="padding-right: 0px;">
                  <div class="ne lg"></div>
                </v-col>
                <v-col cols="auto" style="padding-left: 0px;">
                  <div class="range">
                      <span class="e-r-title" :class="{ 'text-upper': year < 2020 }">
                        NE</span>
                      <span class="score">(Not Evaluated)</span>
                  </div>
                </v-col>

              </v-row>

          </v-container>

</template>

<script>
export default {
  name: 'ERKeyComponent',
  computed: {
    year() {
      return this.$route.params.year;
    },
  },
};
</script>

<style scoped>
  .highly {
      background: #303030;
      width: 100%;
  }
  .effective {
      background: #575757;
  }
  .partially {
      background: #a9a9a9;
  }
  .ineffective {
      background: #e8e8e8;
      border: 1px solid #000;
  }
  .ne {
      background: #fff;
      border: 3px solid #696969;
  }
  .e-r-title {
      font-size: 19px ;
      font-weight: 500;
      line-height: 1 ;
      letter-spacing: 0.01em ;
      font-family: 'Roboto', sans-serif;
  }
  @media only screen and (min-width: 1200px){
      .container {
          flex: 0;
          padding: 0;
      }
      .range {
          float: left;
          margin-left: 5px;
      }
      .score {
          display: block;
      }
      .lg {
          height: 40px;
          min-width: 40px;
          max-width: 40px;
          float: left;
      }
      .center-scores {
          position: relative;
          left: 3%;
      }
  }
  @media only screen and (max-width: 1199px) {
      .container {
          flex: 0;
          padding: 0;
      }
      .highly {
          background: #303030;
      }
      .range {
          float: left;
          margin-left: 5px;
      }

      .lg {
          height: 40px;
          min-width: 40px;
          max-width: 40px;
      }
  }
</style>
