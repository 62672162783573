<template>
  <div>
      <!-- <main-epppr-header :provider="provider" :program="program"
         :year="year"></main-epppr-header> -->
      <page-title-color-bar :items="items"></page-title-color-bar>
      <p-p-mission :provider="provider" :year="year" v-if="!isCeCeas && hasMission"></p-p-mission>
      <p-p-context :provider="provider" :year="year" v-if="!isCeCeas && hasContext"></p-p-context>
      <v-container fluid>
        <v-row no-gutters>
          <v-col>
            <p-p-image :provider="provider" :year="year"></p-p-image>
          </v-col>
          <v-col>
            <p-p-financial-aid :provider="provider" :year="year"></p-p-financial-aid>
          </v-col>
        </v-row>
      </v-container>
      <footnotes :items="notes"></footnotes>
  </div>
</template>

<script>
import PageTitleColorBar from '../../components/PageTitleColorBar.vue';
import footnotes from '../../components/FootnotesComponent.vue';
import PPMission from '../../components/pp/PPMissionComponent.vue';
import PPContext from '../../components/pp/PPContextComponent.vue';
// import MainEppprHeader from '../../components/pp/PPMissionComponent.vue';
import PPImage from '../../components/pp/PPImageComponent.vue';
import PPFinancialAid from '../../components/pp/PPFinancialAidComponent.vue';

export default {
  components: {
    // MainEppprHeader,
    PPMission, PPContext, footnotes, PageTitleColorBar, PPFinancialAid, PPImage,
  },
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
  },
  computed: {
    glossaryLink() {
      return `/glossary/${this.$route.params.year}/${this.$route.params.provider}/${this.$route.params.program}`;
    },
    isCeCeas() {
      const ce = 'NJ CE Providers';
      const ceas = 'NJ CEAS Providers';
      return this.provider === ce
          || this.provider === ceas;
    },
    hasMission() {
      const mission = this.$store.getters.mission(this.provider, this.year);
      return mission.Mission !== 'NA';
    },
    hasContext() {
      const context = this.$store.getters.context(this.provider, this.year);
      return context && context.InstitutionalContext && context.InstitutionalContext !== 'NA';
    },
    notes() {
      const noteList = [];
      if (this.hasMission) {
        noteList.push({ id: 1, note: '<sup>1a</sup> The mission statement was provided to the NJDOE by the institution through the application process to become an approved teacher preparation provider.' });
      }
      if (this.hasContext) {
        noteList.push({ id: 1, note: '<sup>1b</sup> The institutional context statement was provided to the NJDOE by the institution through the application process to become an approved teacher preparation provider.' });
      }
      noteList.push({ id: 2, note: '<sup>2</sup> A partnership is a formal or informal agreement with a school or district where candidates can complete program requirements. Partnerships  are provided by institution.' });
      noteList.push({ id: 3, note: '<sup>3</sup> Teacher preparation programs are accredited at least every 7 years.' });
      noteList.push({ id: 4, note: `<sup>4</sup> More information about financial aid options is available in the <a href="${this.glossaryLink}">glossary</a> at the end of this report.` });
      return noteList;
    },
    missionNote() {
      return [
        { id: 1, note: '<sup>1</sup> The mission statement was provided to the NJDOE by the institution through the application process to become an approved teacher preparation provider.' },
        { id: 2, note: '<sup>2</sup> A partnership is a formal or informal agreement with a school or district where candidates can complete program requirements. Partnerships  are provided by institution.' },
        { id: 3, note: '<sup>3</sup> Teacher preparation programs are accredited at least every 7 years.' },
        { id: 4, note: `<sup>4</sup> More information about financial aid options is available in the <a href="${this.glossaryLink}">glossary</a> at the end of this report.` },
      ];
    },
    items() {
      return [
        {
          icon: 'fas fa-landmark pp',
          colorbar: 'pp-color-bar',
          color: 'pp title-size',
          title: 'Provider Profile',
          to: '/provider-profile',
          subtitle: 'Information that applies to all teacher preparation programs at the institution such as mission and location.',
        },
      ];
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
  .aid-list {
    height: 29px;
    cursor: default;
  }
  .tooltip-text{
    font-size: 1.5rem;
  }
</style>
<style>
    .pp{
        color: #A5121B;
    }
    .container {
        flex: 0;
        padding: 0;
    }

</style>
