const EMPTY_VALUE = '*';

function parseEmpty(value, emptyValue) {
  return value === 'NULL' ? 'NA' : (emptyValue || EMPTY_VALUE);
}
export default {
  methods: {
    percentOrBlank(value, emptyValue) {
      return (value && Number(value)) || value === 0
        ? parseFloat(value).toFixed(1)
        : parseEmpty(value, emptyValue);
    },
    dollarOrBlank(value, emptyValue) {
      return (value && Number(value)) || value === 0
        ? parseFloat(value).toLocaleString()
        : parseEmpty(value, emptyValue);
    },
    numberOrBlank(value, emptyValue) {
      return (value && Number(value)) || value === 0
        ? value
        : parseEmpty(value, emptyValue);
    },
  },
};
