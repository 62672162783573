<template>
  <div id="app" v-if="$store.getters.auth">
    <div id="education">
      <div class="skip"><a href="#content">Skip to Content</a></div>
      <h1 class="sr-only">New Jersey Department of Education</h1>

          <!-- statewide ostream -->

      <div class="container-fluid" id="header">
        <header class="sonj row justify-content-center ">
          <div id="logo" class="col-sm-8 col-lg-6 col-xl-5">
            <h1 class="my-2"><img src="https://www.nj.gov/nj/design/images/state_seal_white.png" alt="State of New Jersey Seal" title="">Official Site of The State of New Jersey</h1>
          </div>
          <div class=" col-sm-4 col-lg-6 col-xl-5">
            <div class="gov-bar text-right d-none d-lg-block"><a href="https://nj.gov/governor/" target="_blank">Governor Phil Murphy &bull; Lt. Governor Sheila Oliver</a> </div>
            <ul  class="my-sm-2 my-lg-0 social statewide text-right">
            <li class="d-none d-lg-inline"><a href="https://nj.gov">NJ.gov</a></li>
            <li class="d-none d-lg-inline"><a href="https://nj.gov/nj/gov/njgov/alphaserv.html">Services</a></li>
            <li class="d-none d-lg-inline"><a href="https://nj.gov/nj/gov/deptserv/">Agencies</a></li>
            <li class="d-none d-lg-inline"><a href="https://nj.gov/faqs/">FAQs</a></li>
            <li class="sonj-translate">
              <a href="https://translate.google.com/">Translate</a>
              <div class="drop px-4 pb-3">
                <button type="button" class="pl-0 btn btn-link float-left drop-close">
                  <i class="fas fa-times-circle"></i> close
                </button>
                <div id="google_translate_element"></div>
                <p class="disclaimer pt-3">The State of NJ site may contain optional links, information, services and/or content from other websites operated by third parties that are provided as a convenience, such as Google™ Translate. Google™ Translate is an online service for which the user pays nothing to obtain a purported language translation. The user is on notice that neither the State of NJ site nor its operators review any of the services, information and/or content from anything that may be linked to the State of NJ site for any reason. -<a href="https://nj.gov/nj/home/disclaimer.html" target="_blank"><strong>Read Full Disclaimer <i class="fas fa-link"></i></strong></a></p>
              </div>
            </li>
            <li class="sonj-search">
              <a href="https://search.state.nj.us/" id="search-label"  role="button">Search <i class="fas fa-search"></i></a>
              <div class="drop px-4 pb-3">
                <button type="button" class="pl-0 btn btn-link float-left drop-close">
                  <i class="fas fa-times-circle"></i> close
                </button>
                <form id="search" action="https://search.state.nj.us/query.html"  name="search">
                  <div class="input-group">
                    <input type="text" class="form-control p-1"
                      placeholder="Type Search Here" aria-label="Search Query"
                      aria-describedby="search-label" name="qt" id="qt">
                    <div class="input-group-append">
                      <button type="submit" class="input-group-text">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>
            </ul>
          </div>
        </header>
      </div>

      <div class="container-fluid d-none d-lg-block">
        <div class="row justify-content-center ">
          <div id="site-name" class="department col-12 col-xl-10 py-3">
            <h2 class="">
              <a href="https://www.nj.gov/education/">
                <img src="https://www.nj.gov/education/assets/img/doe-seal.png" alt="State of New Jersey, Department of Education logo" title="" class="dept-logo pr-1">
                <span class="align-middle department">Department of Education</span>
              </a>
            </h2>
          </div>
        </div>
      </div>

      <aside>
        <div class="x-container-fluid d-none d-sm-inline sticky-social">
          <a href="" class="toggle-sticky">
            <i class="fas fa-angle-double-right fa-1x sonj-gray toggle-target"></i>
            <i class="fas fa-angle-double-left fa-1x sonj-gray d-none  toggle-target"></i>
            <span class="sr-only">Toggle Social Links Open/Close</span>
          </a>
          <a href="https://twitter.com/NewJerseyDOE" class=" toggle-target"><i class="fab fa-twitter-square fa-2x sonj-gray"></i><span class="sr-only">Twitter</span></a>
          <a href="https://www.facebook.com/njdeptofed/" class=" toggle-target"><i class="fab fa-facebook-square fa-2x sonj-gray"></i><span class="sr-only">Facebook</span></a>
          <a href="https://www.instagram.com/newjerseydoe/" class=" toggle-target"><i class="fab fa-instagram fa-2x sonj-gray"></i><span class="sr-only">Instagram</span></a>
        </div>
      </aside>
      <div class="container-fluid sonj-nav education department-level">
      <div class="row justify-content-center ">
        <div id="" class="col-12 col-xl-10">
          <nav class="navbar navbar-expand-lg navbar-dark x-bg-light px-0 justify-content-between">
            <h2 class="navbar-brand x-mr-auto d-lg-none">
              <a href='/education/'>Department of Education</a>
            </h2>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-site"
              aria-controls="navbar-site"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbar-site">
            <ul class="navbar-nav mr-auto x-nav-pills">
              <li class="nav-item  pr-4" id="education-pNav1" data-pnav="home"><a class="nav-link " href="https://nj.gov/education/" data-sNav_scalar="0"  >Home <i class="fas fa-home"></i></a>
              </li>
              <li class="nav-item dropdown pr-4" id="education-pNav2" data-pnav="about"><a class="nav-link dropdown-toggle" href="https://www.nj.gov/education/about" data-sNav_scalar="6" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >About the DOE</a>
                <ul class="dropdown-menu" aria-labelledby="education-pNav2">
                  <li><a href="https://www.nj.gov/education/about/index.shtml" class="dropdown-item">Mission and Vision</a>
                  </li>
                  <li><a href="https://www.nj.gov/education/about/commissioner" class="dropdown-item">Office of the Commissioner</a>
                  </li>
                  <li><a href="https://www.nj.gov/education/about/divisions" class="dropdown-item">Divisions & Offices</a>
                  </li>
                  <li><a href="https://www.nj.gov/education/about/executive/index.shtml" class="dropdown-item">Executive Staff Biographies</a>
                  </li>
                  <li><a href="https://www.nj.gov/education/about/org" class="dropdown-item">Organization Chart</a>
                  </li>
                  <li><a href="https://www.nj.gov/education/about/directions" class="dropdown-item">Locations & Directions</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item  pr-4" id="education-pNav3" data-pnav="educatorshome">
                <a class="nav-link " href="https://www.nj.gov/education/educators/" data-sNav_scalar="0"  >Educators</a>
              </li>
              <li class="nav-item  pr-4" id="education-pNav4" data-pnav="familieshome">
                <a class="nav-link " href="https://www.nj.gov/education/families/" data-sNav_scalar="0"  >Families</a>
              </li>
              <li class="nav-item  pr-4" id="education-pNav5" data-pnav="administratorshome">
                <a class="nav-link " href="https://www.nj.gov/education/administrators/" data-sNav_scalar="0"  >Administrators</a>
              </li>
              <li class="nav-item  pr-4" id="education-pNav6" data-pnav="newshome">
                <a class="nav-link " href="https://www.nj.gov/education/news/" data-sNav_scalar="0"  >News</a>
              </li>
              <li class="nav-item  pr-4" id="education-pNav7" data-pnav="calendar">
                <a class="nav-link " href="https://homeroom5.doe.state.nj.us/events/" data-sNav_scalar="0"  >Events</a>
              </li>
              <li class="nav-item  pr-4" id="education-pNav8" data-pnav="atoz">
                <a class="nav-link " href="https://www.nj.gov/education/atoz" data-sNav_scalar="0"  >A to Z</a>
              </li>
              <li class="nav-item  pr-4" id="education-pNav9" data-pnav="contact">
                <a class="nav-link " href="https://www.nj.gov/education/contact" data-sNav_scalar="0"  >Contact Us</a>
              </li>
            </ul>
            </div>
          </nav>
        </div>
      </div>
      </div>

      <div class="skip"><a name="content">#</a></div>
      <!-- Empty until activated See "Active" example -->

      <main>
        <div class='container-fluid '>
          <div class="row justify-content-center  py-5">
            <div class="col-12 col-xl-10">
              <!-- <div class="sideMenu">
                <div class="sidebar pure-u-1 pure-u-md-1-4">
                  <ul id="sidemenu">
                    <li><a href="https://www.state.nj.us/education/educators/rpr/" class="active">Recruitment, Preparation, and Recognition Home</a></li>
                    <li><a href="https://www.state.nj.us#" @click.prevent="visibleMenu = 'recruitment'">Recruitment</a>
                      <ul v-if="visibleMenu==='recruitment'">
                        <li><a href="https://www.state.nj.us/education/educators/rpr/recruitment/">Overview</a></li>
                        <li><a href="https://www.nj.gov/education/rpi/recruitment/index.shtml">Additional Resources</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.state.nj.us#" @click.prevent="visibleMenu = 'preparation'">Preparation</a>
                      <ul v-if="visibleMenu==='preparation'">
                        <li><a href="https://www.state.nj.us/education/educators/rpr/preparation/">Overview</a></li>
                        <li><a href="https://www.nj.gov/education/rpi/preparation/index.shtml">Preparation Programs</a></li>
                        <li><a href="https://www.state.nj.us/education/educators/rpr/preparation/assessment/">Performance Assessment for Teacher Certification</a></li>
                        <li><a href="https://www.nj.gov/education/rpi/loans/">Financial Aid and Loan Forgiveness/Cancellation</a></li>
                        <li><a href="https://www.nj.gov/education/rpi/preparation/program/">Program Approval and Review</a></li>
                        <li><a href="https://www.nj.gov/education/rpi/preparation/program/spac.shtml">Program Approval Council (SPAC)</a></li>
                        <li><a href="https://www.state.nj.us/education/educators/rpr/preparation/providers/">Performance Reports for Educator Preparation</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.state.nj.us#" @click.prevent="visibleMenu = 'recognition'">Recognition</a>
                      <ul v-if="visibleMenu==='recognition'">
                        <li><a href="https://www.nj.gov/education/recognition/index.shtml">Overview</a></li>
                        <li><a href="https://www.state.nj.us/education/educators/rpr/recognition/educators/">Educator Recognition</a></li>
                        <li><a href="https://www.state.nj.us/education/educators/rpr/recognition/schools/">School Recognition</a></li>
                        <li><a href="https://www.nj.gov/education/recognition/districts/">District Recognition</a></li>
                        <li><a href="https://www.state.nj.us/education/educators/rpr/recognition/resources/">Additional Resources</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.state.nj.us/education/educators/newteacher/">New Teacher Guidance</a></li>
                    <li><a href="https://www.state.nj.us/education/educators/rpr/equity/">Educator Equity</a></li>
                    <li><a href="https://www.state.nj.us/education/educators/rpr/laws/laws.shtml" @click.prevent="visibleMenu = 'laws'">Laws and Guidance</a>
                      <ul v-if="visibleMenu==='laws'">
                        <li><a href="https://www.nj.gov/education/code/">Laws</a></li>
                      </ul>
                    </li>
                    <li><a href="https://www.state.nj.us/education/genfo/faq/faq_rpr.shtml">FAQ</a></li>
                  </ul>
                </div>
              </div> -->
              <div class="appContent">
                <provider-select></provider-select>
                <!-- PUT THE Menu component here -->
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>

      <div class="footer site-footer" >
        <div class="container-fluid">
          <div class="row x-no-gutters justify-content-center">
            <div class="col-lg-9 col-xl-8">
              <h3><a href="https://www.nj.gov/education/">Department of Education</a></h3>
              <div class="columned">
                <ul>
                  <li>
                    <h4><a href="https://nj.gov/education/">Home</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/about">About the DOE</a></h4>
                    <ul  class="d-none d-md-flex">
                      <li><a href="https://www.nj.gov/education/about/index.shtml">Mission and Vision</a></li>
                      <li><a href="https://www.nj.gov/education/about/commissioner">Office of the Commissioner</a></li>
                      <li><a href="https://www.nj.gov/education/about/divisions">Divisions & Offices</a></li>
                      <li><a href="https://www.nj.gov/education/about/executive/index.shtml">Executive Staff Biographies</a></li>
                      <li><a href="https://www.nj.gov/education/about/org">Organization Chart</a></li>
                      <li><a href="https://www.nj.gov/education/about/directions">Locations & Directions</a></li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/educators/">Educators</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/families/">Families</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/administrators/">Administrators</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/news/">News</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://homeroom5.doe.state.nj.us/events/">Events</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/atoz">A to Z</a></h4>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h4><a href="https://www.nj.gov/education/contact">Contact Us</a></h4>
                  </li>
                </ul>
                <ul>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-xl-2">
              <h3>Statewide</h3>
              <ul class="statewide d-inline-block float-left mr-1 mr-sm-5 mr-lg-0">
                <li class=""><a href="//nj.gov/governor/admin/about/">Governor Phil Murphy</a></li>
                <li class="">
                  <a href="//nj.gov/governor/admin/lt/">Lt. Governor Sheila Oliver</a>
                </li>
                <li><a href="//nj.gov/">NJ Home</a></li>
                <li><a href="//nj.gov/nj/gov/njgov/alphaserv.html">Services A to Z</a></li>
                <li><a href="//nj.gov/nj/gov/deptserv/">Departments/Agencies</a></li>
                <li><a href="//nj.gov/faqs/">FAQs</a></li>
                <li><a href="//nj.gov/nj/feedback.html">Contact Us</a></li>
                <li><a href="//nj.gov/nj/privacy.html">Privacy Notice</a></li>
                <li><a href="//nj.gov/nj/legal.html">Legal Statement &amp; Disclaimers</a></li>
                <li><a href="//nj.gov/nj/accessibility.html">Accessibility</a></li>
              </ul>
              <img src="https://www.nj.gov/nj/design/images/digital-nj.png" alt=" Digital NJ Logo (the letters N and J with the sillouette of the state of New Jersey knocked out)" class="digital-nj img-fluid max-width  float-left "/>
            </div>
          </div>
        </div>
      </div>
      <div class="footer sonj-footer" >
        <div class="container-fluid">
          <div class="row no-gutters justify-content-center">
            <div class="col-sm-3 col-xl-2">
              <a href="//nj.gov/opra/" class="mb-4 mr-4 d-inline-block  float-left"><img src="https://www.nj.gov/nj/design/images/opra.png"  alt="Open Public Records Act (OPRA)" class="opra" /></a>
              <a href="https://my.state.nj.us/openam/UI/Login" class="mb-4 d-inline-block float-left"><img src="https://www.nj.gov/nj/design/images/myNJ.png"  alt="My New Jersey Portal" class="mynj" /></a>
            </div>
            <div class="col-sm-9 col-xl-8  text-right">
              <a href="https://twitter.com/NewJerseyDOE" class="ml-1 social"><i class="fab fa-twitter-square fa-2x sonj-white"></i><span class="sr-only">Twitter</span></a>
              <a href="https://www.facebook.com/njdeptofed/" class="ml-1 social"><i class="fab fa-facebook-square fa-2x sonj-white"></i><span class="sr-only">Facebook</span></a>
              <a href="https://www.instagram.com/newjerseydoe/" class="ml-1 social"><i class="fab fa-instagram fa-2x sonj-white"></i><span class="sr-only">Instagram</span></a>
              <br>
              Copyright &copy; State of New Jersey, 1996-2018<br>
              Department of Education<br>
              PO Box 500, <br>
              Trenton, NJ 08625-0500, <br>
              (609)376-3500<br>
            </div>
          </div>
          <div class="row no-gutters justify-content-center">
            <div class="col-12 col-xl-10  text-right"><a href="https://www.tech.nj.gov"><img src="https://www.nj.gov/nj/design/images/njoit.png"  alt="powered ny njoit"/></a></div>
          </div>
        </div>
      </div>

      </footer>

      <a id="back-to-top" href="#" class="btn  btn-lg back-to-top" role="button" title="">
        <i class="fas fa-chevron-up"></i><br>Back<br>to top
      </a>

    </div>
  </div>
</template>

<script>
import ProviderSelect from '../components/ProviderSelect.vue';
import Sleepable from '../mixins/Sleepable';

export default {
  mixins: [Sleepable],
  head: {
    title: {
      inner: 'Educator Preparation Provider Annual Reports',
    },
    meta: [
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { 'http-equiv': 'Content-Type', content: 'text/html;charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' },
      { name: 'keywords', content: ' New Jersey Department of Education Home Page ' },
      { name: 'description', content: 'New Jersey Department of Education Home Page' },
      { name: 'modified', content: '09/26/2019' },
      { name: 'editor', content: 'mleech' },
      { name: 'WT.cg_n', content: 'New DOE Site', body: true },
    ],
    link: [
      { rel: 'canonical', ref: 'https://www.nj.gov/education/', id: 'canonical' },
      { rel: 'stylesheet', href: 'https://www.nj.gov/nj/design/css/bootstrap.min.css' },
      { rel: 'stylesheet', href: 'https://www.nj.gov/nj/design/css/sonj-components.css', type: 'text/css' },
      { rel: 'stylesheet', href: 'https://www.nj.gov/nj/design/css/site-base.css', type: 'text/css' },
      { rel: 'stylesheet', href: 'https://www.nj.gov/nj/design/util/slick/slick.css', type: 'text/css' },
      { rel: 'stylesheet', href: 'https://www.nj.gov/nj/design/util/slick/slick-theme.css', type: 'text/css' },
      { rel: 'stylesheet', href: 'https://www.nj.gov/education/assets/css/styles.css', type: 'text/css' },
      { rel: 'stylesheet', href: 'https://www.state.nj.us/education/css/SecondaryMenuSide.css', type: 'text/css' },
    ],
    script: [
      { src: 'https://www.nj.gov/nj/design/js/jquery-3.3.1.min.js', body: false, async: false },
      { src: 'https://www.nj.gov/nj/design/js/bootstrap.bundle.min.js', body: false, async: false },
      { src: '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit', type: 'text/javascript', body: true },
      {
        type: 'text/javascript',
        inner: `function googleTranslateElementInit() {
              new google.translate.TranslateElement({
                pageLanguage: 'en',
                layout: google.translate.TranslateElement.InlineLayout.VERTICAL
              }, 'google_translate_element');
            }`,
        body: true,
      },
      { src: 'https://www.nj.gov/nj/design/js/ie10-viewport-bug-workaround.js', body: true },
      { src: 'https://www.nj.gov/nj/design/js/sonj-header.js', body: true },
      { src: 'https://www.nj.gov/nj/design/js/sticky-social.js', body: true },
      { src: 'https://www.nj.gov/nj/design/js/navigation.js', body: true },
      // { src: 'https://www.state.nj.us/education/scripts/menu/side/menu1.js', body: true },
      // { src: 'https://www.nj.gov/education/assets/scripts/webtrends.js', body: false },
      // {
      //   type: 'text/javascript',
      //   inner: `var _tag=new WebTrends();
      //   _tag.dcsGetId();
      //   _tag.dcsCollect();`,
      //   body: true,
      // },
    ],
    style: [
      {
        inner: `.card-title, h5.card-title { font-size: 1.25rem; margin-bottom: .75rem; color: #000; }
        .header-blue { color: #00289c; }`,
      },
    ],
  },
  data() {
    return {
      visibleMenu: 'preparation',
    };
  },
  components: {
    ProviderSelect,
  },
  mounted() {
    this.$on('okHead', () => {
      while (!window.jQuery) {
        console.log('Head is loaded, $ not found. Sleeping for 500 ms');
        this.sleep(500);
      }

      window.document.querySelector('#sidemenu ul').setAttribute('style', 'display=none;');
    });
  },
};
</script>

<style scoped>
.sideMenu {
  width: 20%;
  float: left;
}
/* .appContent {
  width: 75%;
  float: right;
} */
</style>
