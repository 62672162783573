<template>
  <v-card class="margin-top">
      <v-card-title class="headline">Performance Assessments</v-card-title>
      <v-card-text>
          <h4>Educative Teacher Performance Assessment (edTPA<v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <span v-on="on"><sup>6</sup></span>
          </template>
              <span class="tooltip-text">edTPA is the Commissioner-approved assessment measuring
                 a candidate’s ability to prepare a lesson, deliver instruction, and assess
                  student learning.
            </span></v-tooltip>) Timeline:</h4>
          <template>
              <v-data-table
                      :headers="headersCount"
                      :items="count"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1">
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                                  <span class="table-header" v-on="on">
                                    {{ props.header.text }}
                                  </span>
                        </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.year"></td>
                      <td v-html="props.item.requirements"></td>
                    </tr>
                  </template>
              </v-data-table>
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CAPerformanceComponent',
  data() {
    return {
      headersCount: [
        { text: 'School Year', value: 'year' },
        { text: 'Requirement and Scoring', value: 'requirements' },
      ],
    };
  },
  computed: {
    count() {
      if (this.$route.params.year >= 2020) {
        return [
          {
            year: '2016-2017',
            requirements: 'Optional pilot',
          },
          {
            year: '2017-2018',
            requirements: 'All CEAS candidates complete the assessment for certification but do not need to meet a specific cut score<br/><br/>All CE holders complete the assessment but do not need to meet a specific cut score if the assessment is taken during this school year',
          },
          {
            year: '2018-2019',
            requirements: 'All CEAS candidates complete the assessment for certification but do not need to meet a specific cut score<br/><br/>All CE holders complete the assessment but do not need to meet a specific cut score if the assessment is taken during this school year',
          },
          {
            year: '2019-2020',
            requirements: 'Cut score set at one standard error of measurement below the national recommendation<br/><strong><em>13-rubric handbooks (32), 15-rubric handbooks (37), 18-rubric handbooks (44)</em></strong>',
          },
          {
            year: '2020-2021',
            requirements: 'Cut score set at one standard error of measurement below the national recommendation<br/><strong><em>13-rubric handbooks (32), 15-rubric handbooks (37), 18-rubric handbooks (44)</em></strong>',
          },
          {
            year: '2021-2022',
            requirements: 'Cut score determined by New Jersey standard setting process',
          },
        ];
      }
      return [
        {
          year: '2017-2018',
          requirements: 'All CEAS candidates complete the assessment for certification but do not need to meet a specific cut score.<br><br> All CE holders complete the assessment but do not need to meet a specific cut score if the assessment is taken during this school year\n',
        },
        {
          year: '2018-2019',
          requirements: 'All CEAS candidates complete the assessment for certification but do not need to meet a specific cut score <br><br>All CE holders complete the assessment but do not need to meet a specific cut score if the assessment is taken during this school year\n',
        },
        {
          year: '2019-2020',
          requirements: 'Cut score set at one standard error of measurement below the national recommendation<br><br> Note: 13-rubric handbooks (cut score of 32), 15-rubric handbooks (cut score of 37), 18-rubric handbooks (cut score of 44)\n',
        },
        {
          year: '2020-2021',
          requirements: 'Cut score determined by New Jersey standard setting process\n',
        },
      ];
    },
  },
};
</script>

<style scoped>
  .v-card__title{
      padding: 0 16px;
      color: #60521B;
  }
  .v-card__text{
      padding: 0 16px;
  }
  .v-card{
      box-shadow: none;
  }
  .margin-top{
      margin-top: 20px;
      width: 100%;
  }
  h4{
      font-weight: normal;
      font-size: 1.2rem;
      color: #60521B;
  }
  .tooltip-text{
      font-size: 1rem;
  }
  sup{
      color: #000000
  }
  a{
      color: #1a237e;
  }
</style>
