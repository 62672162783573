<template>
    <go-top
      fg-color="#fff"
      bg-color="red"
      alt="TOP"
      :bottom="75"
      :bottom-gap="50">
    </go-top>
</template>

<script>
import GoTop from '@inotom/vue-go-top';

export default {
  components: {
    GoTop,
  },
};
</script>
