export default {
  methods: {
    currentSchoolYearStart(year) {
      return year - 1;
    },
    currentSchoolYearEnd(year) {
      return year;
    },
    currentSchoolYearFull(year) {
      return `${year - 1}-${year}`;
    },
    priorSchoolYearStart(year) {
      return (year - 2);
    },
    priorSchoolYearEnd(year) {
      return (year - 1);
    },
    priorSchoolYearFull(year) {
      return `${year - 2}-${year - 1}`;
    },
    prior2SchoolYearStart(year) {
      return (year - 3);
    },
    prior2SchoolYearEnd(year) {
      return (year - 2);
    },
    prior2SchoolYearFull(year) {
      return `${year - 3}-${year - 2}`;
    },
    prior3SchoolYearStart(year) {
      return (year - 4);
    },
    prior3SchoolYearFull(year) {
      return `${year - 4}-${year - 3}`;
    },
    currentSchoolYearShort(year) {
      return `${year - 1}-${(year).toString().substr(2)}`;
    },
    priorSchoolYearShort(year) {
      console.log(year);
      console.log(`${year - 2}-${(year - 1).toString().substr(2)}`);
      return `${year - 2}-${(year - 1).toString().substr(2)}`;
    },
    prior2SchoolYearShort(year) {
      return `${year - 3}-${(year - 2).toString().substr(2)}`;
    },
  },
  computed: {
    currentYear() {
      return this.currentSchoolYearFull(this.year);
    },
    priorYear() {
      return this.priorSchoolYearFull(this.year);
    },
    prior2Year() {
      return this.prior2SchoolYearFull(this.year);
    },
    currenYearStart() {
      return this.currentSchoolYearStart(this.year);
    },
    currentYearEnd() {
      return this.currentSchoolYearEnd(this.year);
    },
    priorYearStart() {
      return this.priorSchoolYearStart(this.year);
    },
    priorYearEnd() {
      return this.priorSchoolYearEnd(this.year);
    },
    prior2YearStart() {
      return this.prior2SchoolYearStart(this.year);
    },
    prior2YearEnd() {
      return this.prior2SchoolYearEnd(this.year);
    },
    prior3Year() {
      return this.prior3SchoolYearFull(this.year);
    },
  },
};
