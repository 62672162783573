<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,
} from 'chart.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
ChartJS.register(ChartDataLabels);
// const { reactiveProp } = mixins;

export default {
  render: function render(createElement) {
    return createElement('div', {
      style: this.styles,
      class: this.cssClasses,
    }, [createElement('canvas', {
      attrs: {
        id: this.chartId,
        width: this.width,
        height: this.chartHeight,
        role: 'img',
        'aria-label': this.ariaLabel,
      },
      ref: 'canvas',
    })]);
  },
  extends: Bar,
  components: {
    ChartDataLabels,
  },
  props: ['options', 'ariaLabel', 'chartHeight'],
  mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    if (!this.chartHeight || this.chartHeight < 200) {
      // eslint-disable-next-line vue/no-mutating-props
      this.chartHeight = 200;
    }
    /* const opts = {
      responsive: false,
      // animation: false, // disables all animations
      maintainAspectRatio: false,
      // type: 'bar',
      indexAxis: 'y',
      barPercentage: 0.7,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          title: {
            align: 'end',
            display: true,
          },
        },
      },
    }; */
    // eslint-disable-next-line vue/no-mutating-props
    // this.options = opts;
    // eslint-disable-next-line vue/no-mutating-props
    // this.options.indexAxis = 'y';
    // eslint-disable-next-line vue/no-mutating-props
    // this.options.scales.xAxes.gridLines.drawOnChartArea = false;
    // eslint-disable-next-line vue/no-mutating-props
    // this.options.scaleShowVerticalLines = false;
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // this.renderChart(this.data, this.options);
  },
};
</script>
