<template>
  <v-card>
      <v-card-title class="ft-headline">Compensation by Region:</v-card-title>
      <v-card-text>
          <template>
              <v-data-table
                      :headers="headersRegion"
                      :items="regions"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1"

              >
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header" v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.Region"></td>
                      <td v-html="props.item.NumberofTeachers" class="text-xs-center"></td>
                      <td v-html="props.item.MedianSalaryAllEmployed ?
                      props.item.MedianSalaryAllEmployed.toLocaleString() :
                       'NA'" class="text-xs-center dollor"></td>
                      <td v-html="props.item.MedianSalaryNewtoWorkforce ?
                       props.item.MedianSalaryNewtoWorkforce.toLocaleString() :
                        'NA'" class="text-xs-center dollor"></td>
                      </tr>
                  </template>
              </v-data-table>
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'FTRegionComponent',
  props: { provider: {}, program: {}, year: {} },
  mixins: [NumberFormatMixin],
  computed: {
    regions() {
      const regionList = this.$store.getters.regions(this.provider, this.program, this.year);
      regionList.forEach((region) => {
        const r = region;
        r.MedianSalaryAllEmployed = this.dollarOrBlank(region.MedianSalaryAllEmployed, 'NA');
        r.MedianSalaryNewtoWorkforce = this.dollarOrBlank(region.MedianSalaryNewtoWorkforce, 'NA');
      });
      return regionList;
    },
  },
  data() {
    return {
      headersRegion: [
        { text: 'Category', value: 'Region' },
        { text: 'Number of Teachers', value: 'NumberofTeachers' },
        { text: 'Median Salary (all teachers)', value: 'MedianSalaryAllEmployed' },
        { text: 'Median Salary (employed program completers)', value: 'MedianSalaryNewtoWorkforce' },
      ],
    };
  },
};
</script>

<style scoped>
  .dollor:before{
      content: "$";
  }
</style>
