<template>
  <v-layout>
      <v-flex>
          <v-card>
            <v-card-text>
              <template>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  class="elevation-1"
                  disable-pagination :hide-default-footer="true">
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header" v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                          <span>
                            {{ props.header.text }}
                          </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.term"></td>
                      <td v-html="props.item.definition"></td>
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </v-card>
      </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'GlossaryComponent',
  props: { items: {} },
  data() {
    return {
      headers: [
        {
          text: 'Term',
          value: 'term',
        },
        { text: 'Definition', value: 'definition' },
      ],
    };
  },
};
</script>

<style scoped>

</style>
