<template>
  <div>
    <!-- <main-epppr-header
      :provider="provider" :program="program" :year="year"></main-epppr-header> -->
    <page-title-color-bar :items="items"></page-title-color-bar>
      <v-container fluid>
        <v-row class="pr-0 pr-md-2 ml-1 pl-md-4" style="max-width:1600px">
          <v-col v-if="year >= 2020" :cols="year < 2020 ? '6': '4'"
             >
            <c-d-total-certs
             v-if="year >= 2020" :provider="provider" :program="program" :year="year">
          </c-d-total-certs>
          </v-col>
          <v-col :cols="year < 2020 ? '6': '4'" class="">
            <c-d-completers :provider="provider" :program="program" :year="year"></c-d-completers>
          </v-col>
          <v-col :cols="year < 2020 ? '6': '4'" class="">
            <c-d-certificates
             :provider="provider" :program="program" :year="year"></c-d-certificates>
          </v-col>
        </v-row>
        <v-row  class="pl-4 pr-4">
          <v-col cols="12">
            <c-d-gender  :provider="provider" :program="program" :year="year"></c-d-gender>
          </v-col>
        </v-row>
        <v-row  class="pl-4 pr-4">
          <v-col cols="12">
            <c-d-race  :provider="provider" :program="program" :year="year"></c-d-race>
          </v-col>
        </v-row>
        <!-- <v-layout row wrap >
          <c-d-gender  :provider="provider" :program="program" :year="year"></c-d-gender>
          <c-d-race  :provider="provider" :program="program" :year="year"></c-d-race>
        </v-layout> -->
      </v-container>
    <footnotes :items="notes"></footnotes>
    </div>
</template>

<script>

import PageTitleColorBar from '../../components/PageTitleColorBar.vue';
// import MainEppprHeader from '../../../../components/MainEppprHeader';
import CDCompleters from '../../components/cd/CDCompletersComponent.vue';
import CDCertificates from '../../components/cd/CDCertificatesComponent.vue';
import CDTotalCerts from '../../components/cd/CDTotalCertsComponent.vue';
import CDGender from '../../components/cd/CDGenderComponent.vue';
import CDRace from '../../components/cd/CDRaceComponent.vue';
import footnotes from '../../components/FootnotesComponent.vue';

export default {
  components: {
    footnotes,
    CDRace,
    CDGender,
    CDCertificates,
    CDCompleters,
    CDTotalCerts,
    // MainEppprHeader,
    PageTitleColorBar,
  },
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
  },
  computed: {
    glossaryLink() {
      return `/glossary/${this.$route.params.year}/${this.$route.params.provider}/${this.$route.params.program}`;
    },
    notes() {
      return [
        {
          id: 14,
          note: `* Note: The NJDOE collects demographic data through NJSMART and TCIS. For more information see the <a href="${this.glossaryLink}">glossary</a>.`,
        },
      ];
    },
    completerTitle() {
      if (this.year >= 2020) {
        return 'Certified Completer Demographic Information';
      }
      return 'Completer Demographics';
    },
    completerSubTitle() {
      if (this.year >= 2020) {
        return 'Demographic information about candidates who completed a program at this institution and earned a teacher certification in New Jersey.';
      }
      return 'Demographic information for candidates who completed a program at this institution and earned teacher certification in New Jersey.';
    },
    items() {
      return [
        {
          icon: 'fas fa-graduation-cap cd',
          colorbar: 'cd-color-bar',
          color: 'cd title-size',
          title: this.completerTitle,
          to: '/completer-demographics',
          subtitle: this.completerSubTitle,
        },
      ];
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style>
  .cd{
  color: #114d72;
  }
  .container {
    flex: 0;
    padding: 0;
  }
  .v-icon.fa-sort-up,
  .v-icon.fa-sort-down {
    font-size: 18px !important;
    color: #2C7FC3 !important;
  }
</style>
