<template>
  <div>
      <div class="offscreen">
          {{ items.join(', ') }}
      </div>
  </div>
</template>

<script>

import * as d3 from 'd3';

export default {
  name: 'BubbleChart',
  props: ['percent'],
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    const newPercent = [];
    const count = this.percent.percent.length;
    for (let i = 0; i < count; i += 1) {
      const item = this.percent.percent[i];
      const r = item * 0.8;
      newPercent[i] = {
        r: r > 0.01 && r < 30 ? 30 : r,
        value: item === undefined || Number.isNaN(item) ? 0 : item.toFixed(1),
        label: this.percent.labels[i].replace(/ Effective/, ''),
      };
      if (newPercent[i].value > 0) {
        this.items.push(`${newPercent[i].label} ${newPercent[i].value}%`);
      }
    }

    const data = { name: 'root', children: newPercent };

    const svg = d3.select(this.$el)
      .append('svg')
      .attr('viewBox', '-130 -130 260 260')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('aria-hidden', 'true');

    // eslint-disable-next-line no-unused-vars
    const self = this;

    const children = d3.packSiblings(data.children);

    // eslint-disable-next-line no-unused-vars
    const bubbles = svg.selectAll('circle')
      .data(children)
      .enter()
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => d.r)
      .attr('class', (d) => d.label);

    // eslint-disable-next-line no-unused-vars
    const texts = svg.selectAll(null)
      .data(children)
      .enter()
      .append('text')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + 5)
      .attr('text-anchor', 'middle')
      .text((d) => `${d.value}%`)
      .attr('class', (d) => `${d.label}-text`)
      .attr('font-size', 15)
      .attr('font-family', 'Helvetica Neue, Helvetica, Arial, san-serif')
      .attr('id', (d) => `display-${d.r}`)
      .attr('aria-label', (d) => `${d.label} ${d.value}%`);
  },
};
</script>

<style>
  .offscreen{
      position: absolute;
      left: -100000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
  }
</style>
