<template>
  <v-card>
      <v-card-title class="ft-headline">Employment by School Category:</v-card-title>
      <v-card-text>
          <template>
              <v-data-table
                      :headers="headersCategory"
                      :items="categories"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1"

              >
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header" v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.SchoolCategory"></td>
                      <td v-html="props.item.EmployedasofOct15" class="text-xs-center"></td>
                      <td v-html="props.item.PercentageEmployedTeachers"
                      class="text-xs-center percent"></td>
                      <td v-html="props.item.PercentageEmployedTeachersState"
                      class="text-xs-center percent"></td>
                    </tr>
                  </template>
              </v-data-table>
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
import DateFormatMixin from '../../mixins/date-format-mixins';
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'FTCategoryComponent',
  props: { provider: {}, program: {}, year: {} },
  mixins: [DateFormatMixin, NumberFormatMixin],
  computed: {
    categories() {
      const categoryList = this.$store.getters.categories(this.provider, this.program, this.year);
      categoryList.forEach((category) => {
        const c = category;
        c.PercentageEmployedTeachers = this.percentOrBlank(category.PercentageEmployedTeachers);
        c.PercentageEmployedTeachersState = this.percentOrBlank(
          category.PercentageEmployedTeachersState,
        );
      });
      return categoryList;
    },
    headersCategory() {
      return [
        { text: 'Category', value: 'SchoolCategory' },
        { text: `Employed as of October 15, ${this.currentSchoolYearStart(this.year)}`, value: 'EmployedasofOct15' },
        { text: 'Percent Employed as Teachers', value: 'PercentageEmployedTeachers' },
        { text: 'Percentage Employed Statewide', value: 'PercentageEmployedTeachersState' },
      ];
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
  .percent:after{
      content: "%";
  }
</style>
