<template>
  <div>
  <v-card-title class="mission-headline headline" style="color: #A5121B;">Institutional Context
     <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on"><sup>1b</sup></span>
      </template>
      <span class="tooltip-text">The institutional context statement was provided
         to the NJDOE by the institution through the application
        process to become an approved teacher preparation provider.
      </span></v-tooltip>:</v-card-title>
  <v-card-text>
      <p class="mission-text">{{ contextData.InstitutionalContext }}</p>
  </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'PPContextComponent',
  props: { provider: {}, year: {} },
  computed: {
    contextData() {
      return this.$store.getters.context(this.provider, this.year);
    },
  },
};
</script>

<style scoped>
  .tooltip-text{
      font-size: 1rem;
  }
  p.mission-text {
      color: #595959;
  }
</style>
