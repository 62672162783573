<template>
  <div
    id="e3"
    style="margin: auto;"
    class="grey lighten-3">
    <v-app light>
      <v-toolbar color="#002b5c">
        <v-toolbar-title class="white--text" v-text="title"></v-toolbar-title>
        <v-spacer></v-spacer>
        <a href="http://www.publicconsultinggroup.com">
          <img style="height: 50px;" src="@/assets/footer-logo-2.png" alt="footer logo">
        </a>
      </v-toolbar>
      <v-content>
        <v-container>
          <v-toolbar color="#002b5c">
            <v-toolbar-title class="white--text">Login Page</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="white" style="transform: translateX(-10px)">
              <span style="font-size: 3em; margin: 0 5px;" class="mdi mdi-power"></span>
            </v-btn>
          </v-toolbar>
          <v-card>
            <v-form>
              <v-container v-if="authState==='SignIn'">
                <v-layout row wrap>
                  <v-flex md12>
                    <v-text-field
                      v-model="username"
                      single-line
                      label="User Name">
                    </v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      hint="At least 8 characters"
                      counter
                      @click:append="show1 = !show1"
                      @keyup.enter="login">
                    </v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <span class="support-info">
                      For information please contact
                      <a href="mailto:tvybornova@pcgus.com">Support</a>
                    </span>
                    <v-btn class="right"
                      color="#002b5c"
                      style="color: #fff; float: right; margin: 10px;" @click="login">login
                      <span class="mdi mdi-power"></span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container v-if="authState==='MfaRegister'">
                <v-layout row wrap>
                  <v-flex md12>
                    <v-text-field
                      v-model="userphone"
                      single-line
                      :mask="'(###) ###-####'"
                      :rules="[rules.phoneMin]"
                      label="User Mobile Phone Number">
                    </v-text-field>
                  </v-flex>
                  <v-flex md12>
                  </v-flex>
                  <v-flex md12>
                    <v-btn
                      class="right"
                      outline color="#002b5c"
                      id="register"
                      @click="registerPhone">
                      register
                      <span class="mdi mdi-power"></span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container v-if="authState==='MfaVerify'">
                <v-layout row wrap>
                  <v-flex md12>
                    <v-text-field
                      name="input-11-1"
                      label="SMS Code"
                      v-model="mfaCode"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-btn class="right"
                      outline color="#002b5c"
                      style="color: #fff; float: right;" @click="verifyMfaCode">verify
                      <span class="mdi mdi-power"></span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container v-if="authState==='VerifyEmail'">
                <v-flex md12>
                  <p>You are required to verify your email address prior to setting up MFA.
                  Please check your email and follow the steps to complete verification.</p>
                  <v-btn class="center" outline color="#002b5c" @click="resendVerifyEmail">
                    resend email
                    <v-icon>email</v-icon>
                  </v-btn>
                </v-flex>
              </v-container>
            </v-form>
            <div id="recaptcha-container"></div>
          </v-card>
        </v-container>
      </v-content>
      <v-footer color="#002046" app>
        <v-flex class="right">
          <span  style="margin-left: 20px;">
            <a class="white--text" href="http://www.publicconsultinggroup.com/privacy-policy/">Privacy Policy</a>
          </span>
          <span class="white--text" style="margin-left: 20px;">|</span>
          <span style="margin-left: 20px;">
            <a class="white--text" href="http://www.publicconsultinggroup.com/terms-of-use/">Terms of Use</a>
          </span>
          <span class="white--text" style="margin-left: 20px;">|</span>
          <span class="white--text" style="margin-left: 20px;">
            © Copyright 2018 Public Consulting Group
          </span>
          <a href="http://www.publicconsultinggroup.com">
            <img
              alt="Footler Logo 2"
              style="height: 20px; float: right; margin-right: 20px;"
              src="@/assets/footer-logo-2.png">
          </a>
        </v-flex>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  // signInWithPhoneNumber,
  multiFactor,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

export default {
  data: () => ({
    show1: false,
    username: '',
    password: '',
    userphone: '',
    mfaCode: '',
    userCredential: {},
    token: null,
    authState: 'SignIn',
    registerFlow: false,
    auth: {},
    appVerifier: {},
    resolver: {},
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => ('The email and password you entered don\'t match'),
      phoneMin: (v) => v.length >= 10 || 'Phone must be 10 characters or more',
    },
    title: 'Public Consulting Group',
  }),
  middleware: 'notAuthenticated',
  mounted() {
  },
  created() {
    initializeApp({
      apiKey: 'AIzaSyD9ApdHy33xsZuDB9qNvb3UBHU-I4-ti5o',
      authDomain: 'njsmart-epppr.firebaseapp.com',
      databaseURL: 'https://njsmart-epppr.firebaseio.com',
      projectId: 'njsmart-epppr',
      storageBucket: 'njsmart-epppr.appspot.com',
      messagingSenderId: '613597423532',
      appId: '1:613597423532:web:599886cbfd0bdfd6c13c09',
    });
    this.auth = getAuth();
  },
  methods: {
    login() {
      // Process Login
      signInWithEmailAndPassword(this.auth, this.username, this.password)
        .then((userCred) => {
          if (userCred.user.emailVerified) {
            this.authState = 'MfaRegister';
            this.userCredential = userCred;
            this.token = userCred.user.accessToken;
          } else {
            this.authState = 'VerifyEmail';
            sendEmailVerification(this.auth.currentUser);
          }
        })
        .catch((error) => {
          if (error.code === 'auth/multi-factor-auth-required') {
            this.initReCaptcha();
            this.authState = 'MfaVerify';
            this.resolver = getMultiFactorResolver(this.auth, error);
            const phoneOptions = {
              multiFactorHint: this.resolver.hints[0],
              session: this.resolver.session,
            };
            const phoneAuthProvider = new PhoneAuthProvider(this.auth);
            phoneAuthProvider.verifyPhoneNumber(phoneOptions, this.appVerifier)
              .then((verificationId) => {
                this.verificationId = verificationId;
              });
          }
        });
    },
    resendVerifyEmail() {
      sendEmailVerification(this.auth.currentUser);
    },
    registerPhone() {
      this.initReCaptcha();
      const vm = this;
      multiFactor(this.userCredential.user).getSession()
        .then((multiFactorSession) => {
          const phoneOptions = {
            phoneNumber: `+1${vm.userphone}`,
            session: multiFactorSession,
          };
          const phoneAuthProvider = new PhoneAuthProvider(vm.auth);
          phoneAuthProvider.verifyPhoneNumber(phoneOptions, vm.appVerifier)
            .then((verificationId) => {
              vm.verificationId = verificationId;
              vm.authState = 'MfaVerify';
              vm.registerFlow = true;
            });
        })
        .catch(() => {
          vm.appVerifier.clear();
        });
    },
    verifyMfaCode() {
      const cred = PhoneAuthProvider.credential(this.verificationId, this.mfaCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      if (this.registerFlow) {
        multiFactor(this.userCredential.user).enroll(multiFactorAssertion, 'Cell Phone');
        this.$store.dispatch('setLoginToken', this.userCredential.user.accessToken);
        this.$router.push('/');
      } else {
        this.resolver.resolveSignIn(multiFactorAssertion)
          .then((userCredential) => {
            this.userCredential = userCredential;
            this.$store.dispatch('setLoginToken', this.userCredential.user.accessToken);
            this.$router.push('/');
          });
      }
    },
    initReCaptcha() {
      window.recaptchaVerifier = new RecaptchaVerifier(this.auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          // Allow Sign In
          console.log('Allow Sing In');
        },
        'expired-callback': () => {
          // Response Expired
          console.log('Expired');
        },
      });

      this.appVerifier = window.recaptchaVerifier;
    },
    sentLogin() {
      setTimeout(() => {
        const loginData = {
          username: this.username,
          password: this.password,
        };
        this.$store.dispatch('loginPost', loginData);
      }, 3600);
    },
  },
};
</script>

<style scoped>
#e3{
  width: 50%;
}
.v-text-field, .support-info {
  margin: 5px 15px;
}
.v-btn {
  margin: 5px 15px;
}
.v-toolbar__title {
  margin: 5px 15px;
}
</style>
