<template>
  <v-app v-if="$store.getters.auth">
    <main-menu :year="year" :provider="provider" :program="program"></main-menu>
    <main-epppr-header :provider="provider" :program="program" :year="year"></main-epppr-header>
    <go-top></go-top>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import MainMenu from '../components/MainMenu.vue';
import MainEppprHeader from '../components/MainEppprHeader.vue';
import GoTop from '../components/GoTop.vue';

export default {
  components: { MainMenu, MainEppprHeader, GoTop },
  mixins: [],
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  data() {
    return {
      provider: '',
      program: '',
      year: '',
      window: {
        width: 0,
        height: 0,
      },
      drawer: false,
    };
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destoryed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style>
.v-data-table-header tr th span{
  color: white!important;
  font-size: 13px;
}
.v-data-table-header tr th {
  background-color: rgb(89 89 89)!important;
}
.c-d-card{
  /* margin: 6px!important; */
}
.c-d-card-title{
  padding-left: 2px!important;
  font-size: 14px;
}
/* .c-d-title{
      margin-top: 20px;
      font-size: 14px !important;
      font-weight: 700;
} */

  .c-p-count{
    font-size: 1.2rem !important;
    text-align: center;
    font-family: 'Lato', sans-serif;
    padding-top: 16px !important;
    padding-left: 19px !important;
    margin-left: -15%;
    width: 80px;
    color: #114d72;
  }
  .c-p-count-large{
      margin-left: -20%;
  }
  .cd-headline{
        color: #114d72;
        padding: 0 15px 10px 15px !important;
        margin: 0 !important;
        font-size: 1.5rem!important;
    }
  .cd-title-text{
    margin-top: 20px;
    margin-left: 12px;
    font-size: 1.1rem;
    color: #114d72;
    white-space: pre-wrap;
    word-break: break-word;
  }
.fa-check{
  color: #4caf50!important;
  caret-color : #4caf50!important;
  font-size: 16px!important;
}
.fa-times-circle{
  color: red!important;
  caret-color : red!important;
  font-size: 16px!important;
}
.theme--light.application {
  background: white;
}
.title-size {
  font-size: 2rem;
}
a{
  text-decoration: none;
}
.v-card__title {
  padding: 16px 16px 0 16px;
}
.theme--light.v-list .v-list__tile__sub-title {
  color: #595959;
}
.v-list-item{
  min-height: 35px;
}
.page-number-wrapper{
  display: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .application--wrap{
    display: block !important;
  }
  .v-application{
    display: block;
  }
}
body {
  /* background: #ECEFF1; color: rgba(0,0,0,0.87); */
  color: rgba(0,0,0,0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif; margin: 0;
  padding: 0; }
      #message { background: white; max-width: 360px;
        margin: 100px auto 16px; padding: 32px 24px; border-radius: 3px;
      }
      #message h2 { color: #ffa100; font-weight: bold; font-size: 16px; margin: 0 0 8px; }
      #message h1 { font-size: 22px; font-weight: 300; color: rgba(0,0,0,0.6); margin: 0 0 16px;}
      #message p { line-height: 140%; margin: 16px 0 24px; font-size: 14px; }
      #message a { display: block; text-align: center;
        background: #039be5; text-transform: uppercase;
        text-decoration: none; color: white; padding: 16px; border-radius: 4px; }
      #message, #message a { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
      #load { color: rgba(0,0,0,0.4); text-align: center; font-size: 13px; }
      @media (max-width: 600px) {
        body, #message { margin-top: 0; background: white; box-shadow: none; }
        body { border-top: 16px solid #ffa100; }
      }
</style>
