<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <div>
          <img :src="'/assets/maps/' + image.FileName" class="map" :title="'Map of '
              + image.FileName" :alt="'Map of ' + image.FileName" />
          <!-- <v-card tile flat color="#eaedf2">
              <img :src="'/maps/' + image.FileName" class="map" :title="'Map of '
              + image.FileName" :alt="'Map of ' + image.FileName" />
              <v-card-text >
              </v-card-text>
          </v-card> -->
      </div>
      </v-col>
    </v-row>
    <v-row no-gutters style="padding-left: 30px;font-size: 12px;">
      <v-col>
        <div style="" v-if="!isCeCeas">
          Address:    <a :href="'https://www.google.com/maps/place/' + address.Address" target="_blank">{{ address.Address }}</a>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters style="padding-left: 30px;font-size: 12px"
    v-if="website && website.Website !== 'NA'">
      <v-col>
        <div>
          Website:   <a :href="website.Website" target="_blank">{{ website.Website }}</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PPImageComponent',
  props: { provider: {}, year: {} },
  computed: {
    image() {
      return this.$store.getters.map(this.provider, this.year);
    },
    address() {
      return this.$store.getters.profile(this.provider, this.year);
    },
    website() {
      return this.$store.getters.profile(this.provider, this.year);
    },
    isCeCeas() {
      const ce = 'NJ CE Providers';
      const ceas = 'NJ CEAS Providers';
      return this.provider === ce || this.provider === ceas;
    },
    altText() {
      return this.image.ProviderName;
    },
  },
};
</script>

<style>
.map{
width: 90%;
height: 85%;
margin-top: 10px;
margin-left: 20px;
}
</style>
