<template>
  <v-card>
      <v-card-title class="ft-headline">Employment by Certification Area:</v-card-title>
      <v-card-text>
          <template>
              <v-data-table
                      :headers="headersArea"
                      :items="areaData"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1"

              >
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header" v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.Certification"></td>
                      <td v-html="props.item.CountofCertifiedIndividuals"
                       class="text-xs-center"></td>
                      <td v-html="props.item.EmployedasofOct15" class="text-xs-center"></td>
                      <td v-html="props.item.PercentageEmployedTeachers"
                       class="text-xs-center percent"></td>
                    </tr>
                  </template>
              </v-data-table>
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
import DateFormatMixin from '../../mixins/date-format-mixins';
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'FTAreaPrintOneComponent',
  props: { provider: {}, year: {} },
  mixins: [NumberFormatMixin, DateFormatMixin],
  computed: {
    areas() {
      const areaList = this.$store.getters.areas(this.provider, this.year);
      areaList.forEach((area) => {
        // eslint-disable-next-line no-param-reassign
        area.PercentageEmployedTeachers = this.percentOrBlank(area.PercentageEmployedTeachers);
      });
      return areaList;
    },
    areaData() {
      const a = this.areas;
      return a.slice(0, 16);
    },
    headersArea() {
      return [
        { text: 'Category', value: 'Certification' },
        { text: 'Count of Certified Individuals', value: 'individuals' },
        { text: `Employed as of October 15, ${this.currentSchoolYearStart(this.year)}`, value: 'EmployedasofOct15' },
        { text: 'Percent Employed as Teachers', value: 'PercentageEmployedTeachers' },
      ];
    },
  },
};
</script>

<style scoped>
  .percent:after{
      content: "%";
  }
</style>
