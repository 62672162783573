import { render, staticRenderFns } from "./FTAreaComponent.vue?vue&type=template&id=45e6132c&scoped=true&"
import script from "./FTAreaComponent.vue?vue&type=script&lang=js&"
export * from "./FTAreaComponent.vue?vue&type=script&lang=js&"
import style0 from "./FTAreaComponent.vue?vue&type=style&index=0&id=45e6132c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e6132c",
  null
  
)

export default component.exports