<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-card style="margin-top: 3px;">
      <v-card-title class="headline">Basic Skills Assessment</v-card-title>
      <v-card-text>
          <h4>Praxis Core Results:</h4>
          <p v-if="this.year < 2022">
            This data is not yet available but may be included in future iterations.</p>
          <template v-else>
            <v-data-table
              :headers="headersArea"
              :items="prxcore"
              class="elevation-1"
              disable-pagination :hide-default-footer="true"
            >
            <template v-for="header in headersArea"
            v-slot:[`header.${header.value}`]="{ header }"
            >
              <span tabindex="0" v-html="header.text" :key="header.id"></span>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CABasicComponent',
  props: { provider: {}, program: {}, year: {} },
  computed: {
    prxcore() {
      return this.$store.getters.prxcore(this.provider, this.program, this.year);
    },
  },
  data() {
    return {
      headersArea: [
        { text: 'Praxis Core Assessment', value: 'TestName' },
        { text: this.year >= 2022 ? 'Average Score for Program Completers' : 'NA', value: 'AvgScaledScore' },
        { text: 'Test Cut Score', value: 'CutScore' },
        { text: 'State Average Scaled Score', value: 'StateAvgScaledScore' },
        { text: 'State First-Time Pass Rate<sup>7</sup>', value: 'StateFirstTimePassRate' },
        { text: 'State Overall Pass Rate<sup>8</sup>', value: 'StateOverallPassRate' },
        { text: 'Provider First-Time Pass Rate<sup>7</sup>', value: 'FirstTimePassRate' },
        { text: 'Provider Overall Pass Rate<sup>8</sup>', value: 'OverallPassRate' },
      ],
    };
  },
};
</script>

<style scoped>
  .v-card__title{
      padding: 0 16px;
      color: #60521B;
  }
  .v-card__text{
      padding: 0 16px;
  }
  .margin-top{
      margin-top: 20px;
  }
  h4{
      font-weight: normal;
      font-size: 1.2rem;
      color: #60521B;
  }
  .tooltip-text{
      font-size: 1.5rem;
  }
</style>
