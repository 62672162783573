<template>
  <v-flex>
    <v-card>
      <v-card-text class="home-text">
        <p>The goal of this report is to share the available state data on novice teachers that
            this Educator Preparation Provider (EPP) recommended for certification. To create
            the report, the New Jersey Department of Education (NJDOE) has synthesized data
            from multiple sources. A list of these sources is available at the end of this
            report. Unless otherwise indicated, the data used in this report represents a one
            year cohort of teachers who earned a {{ certificateType }} in the
            {{ this.prior2SchoolYearFull(this.year) }} school year who may have been employed
            in the {{ this.currentSchoolYearFull(this.year) }} school year in a New Jersey
            public school. For additional details see further explanation in the glossary on
            the last page.</p>
        <p>This report provides information in the following key areas:</p>
      </v-card-text>
      <v-list two-line>
        <div v-for="(item, index) in items" :key="index">
          <v-list-item>
            <router-link :to="`${item.to}/${year}/${provider}/${program}`"
              :title="item.title" class="page-link">
              <i :class="item.icon"></i>
            </router-link>
            <v-list-item-content>
              <v-list-item-title :class="item.color">
                <router-link :to="`${item.to}/${year}/${provider}/${program}`"
                  :title="item.title">
                  <span :class="item.color">{{ item.title }}</span>
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index + 1 < items.length"></v-divider>
        </div>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import DateFormatMixin from '../../mixins/date-format-mixins';

export default {
  components: { },
  mixins: [DateFormatMixin],
  validate({ store, params }) {
    if (typeof store.getters.header(params.provider, params.program, params.year) !== 'object') {
      return false;
    }
    return true;
  },
  created() {
    this.provider = this.$route.params.provider;
    this.program = this.$route.params.program;
    this.year = this.$route.params.year;
  },
  computed: {
    certificateType() {
      if (this.year >= 2020) {
        if (this.provider === 'NJ CE Providers' || this.provider.endsWith('- CE')) {
          return 'Certificate of Eligibility (CE)';
        }
        return 'Certificate of Eligibility with Advanced Standing (CEAS)';
      }
      return 'Certificate of Eligibility with Advanced Standing (CEAS)';
    },
    completerTitle() {
      if (this.year >= 2020) {
        return 'Certified Completer Demographic Information';
      }
      return 'Completer Demographics';
    },
    completerSubTitle() {
      if (this.year >= 2020) {
        return 'Demographic information about candidates who completed a program at this institution and earned a teacher certification in New Jersey.';
      }
      return 'Demographic information for candidates who completed a program at this institution and earned teacher certification in New Jersey.';
    },
    certificationTitle() {
      if (this.year >= 2020) {
        return 'Certification Assessment Results';
      }
      return 'Certification Assessments';
    },
    fullTimeTitle() {
      if (this.year >= 2020) {
        return 'Full Time Employment Outcomes and Compensation';
      }
      return 'Full Time Employment Outcomes';
    },
    fullTimeSubTitle() {
      if (this.year >= 2020) {
        return 'Data about certified program completer performance on licensure assessments.';
      }
      return 'Data about program completers from this institution  working in New Jersey public schools.';
    },
    evaluationTitle() {
      if (this.year >= 2020) {
        return 'Evaluation Results and Impact Data';
      }
      return 'Evaluation Results';
    },
    items() {
      return [
        {
          icon: 'fas fa-landmark pp',
          colorbar: 'pp-color-bar',
          color: 'pp title-size',
          title: 'Provider Profile',
          to: '/provider-profile',
          subtitle: 'Information that applies to all teacher preparation programs at the institution such as mission and location.',
        },
        {
          icon: 'fas fa-graduation-cap cd',
          colorbar: 'cd-color-bar',
          color: 'cd title-size',
          title: this.completerTitle,
          to: '/completer-demographics',
          subtitle: this.completerSubTitle,
        },
        {
          icon: 'fas fa-chart-line ca',
          colorbar: 'ca-color-bar',
          color: 'ca title-size',
          title: this.certificationTitle,
          to: '/certification-assessments',
          subtitle: 'Data about program completer performance on required licensure assessments.',
        },
        {
          icon: 'fas fa-handshake pt',
          colorbar: 'pt-color-bar',
          color: 'pt title-size',
          title: this.fullTimeTitle,
          to: '/full-time-employment',
          subtitle: this.fullTimeSubTitle,
        },
        {
          icon: 'fas fa-clipboard-list er',
          colorbar: 'er-color-bar',
          color: 'er title-size',
          title: this.evaluationTitle,
          to: '/evaluation-results',
          subtitle: `Evaluation data for certified program completers from the ${this.prior2SchoolYearShort(this.year)} school year employed in the ${this.priorSchoolYearShort(this.year)} school year.`,
        },
        {
          icon: 'fas fa-asterisk gl',
          colorbar: 'gl-color-bar',
          color: 'gl title-size',
          title: 'Glossary',
          to: '/glossary',
        },
      ];
    },
  },
};
</script>

<style scoped>
.page-link {
  padding-right: 15px;
  padding-bottom: 15px;
}

.fa {
  width: 50px;
}
.fas{
  font-size: 3rem;
  float: left;
  margin-top: 20px;
  margin-left: 15px;
  width: 50px;
}
.title-size{
  font-size: 2rem;
  overflow: visible;
  font-weight: 400;
}
a{
  text-decoration: none;
}
.pp{
color: #A5121B;
}
.cd{
color: #114d72;
}
.ca{
color: #60521B;
}
.pt{
color: #952E0F;
}
.er{
color: #006100;
}
.gl{
  color: #000000;
}
.home-text{
  color: #595959;
  font-size: 22px;
  line-height: 1.5;
}
@media only screen and (max-width: 500px){
  .title-size{
    font-size: 1.3rem;
  }
}
</style>
