export default {
  methods: {
    sleep(milliseconds) {
      const date = Date.now();
      let curDate = null;
      do {
        curDate = Date.now();
      } while (curDate - date < milliseconds);
    },
  },
};
