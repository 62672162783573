<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
