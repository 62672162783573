<template>
    <v-card tile flat class="border cd-tile-card"
     style="margin-left:2px!important; margin-right:2px!important">
      <v-card-title class="cd-title">
            <span class="fa-stack fa-lg">
                <i class="fa fa-graduation-cap fa-stack-2x"
                  style="font-size: 4rem; color: #DCDCDC;"></i>
                <i class="fa fa-stack-1x c-p-count" :class="countClass">
                  {{ completer.TotalCount }}</i>
            </span>
            <span class="cd-title-text" :class="titleSpacerClass">{{ completerLabel }}</span>
        </v-card-title>
    </v-card>
</template>

<script>
export default {
  name: 'CDCompletersComponent',
  props: { provider: {}, program: {}, year: {} },
  mounted() {
    console.log('CDCompleters Mounted');
  },
  computed: {
    completerLabel() {
      if (this.year >= 2020) {
        return 'Total Completer Count';
      }
      return 'Total Completers';
    },
    completer() {
      return this.$store.getters.completer(this.provider, this.program, this.year);
    },
    titleSpacerClass() {
      return this.completer.TotalCount > 999 ? 'four-digit' : '';
    },
    countClass() {
      return this.completer.TotalCount > 999 ? 'c-p-count-large' : '';
    },
  },
};
</script>

<style scoped>
  .border{
      border: 1pt solid #DCDCDC;
      /* min-height: 150px; */
      margin-top: 14px;
  }

  /* .cd-title{
    font-size: 1rem;
    padding: 2px;
  } */
  .small {
      max-width: 300px;
      margin:  15px auto;
  }
  .chart-title{
      font-size: 1.4em;

  }
  .centered{
      margin-left: 40%;
      float: left;
      height: 100px;
  }
  .gender{
      height: 40px;
  }
  .female{
      padding: 15px 300px;
      background: #696969;
      color: white;
      font-weight: bold;
      border: 1px solid #696969;
  }
  .male{
      padding: 15px 60px;
      background: #ffffff;
      border: 1px solid #696969;
      margin: 0;
      font-weight: bold;
      position: relative;
      right: 4px;
  }
  .unknown{
      font-weight: bold;
  }
  .headline{
      color: #114d72;
  }
  .four-digit{
    padding-left: 10px;
  }
  .v-card{
    min-height: 140px!important;
  }
</style>
