<template>
  <v-card>
      <v-card-title class="ft-headline">Employment by School Classification:</v-card-title>
      <v-card-text>
          <template>
              <v-data-table
                      :headers="headersClassification"
                      :items="classifications"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1"

              >
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header"  v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="addFootnote(props.item.SchoolClassification)"></td>
                      <td v-html="props.item.EmployedasofOct15" class="text-xs-center"></td>
                      <td v-html="props.item.PercentageEmployedTeachers"
                      class="text-xs-center percent"></td>
                      <td v-html="props.item.PercentageEmployedTeachersState"
                      class="text-xs-center percent"></td>
                    </tr>
                  </template>
              </v-data-table>
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
import NumberFormatMixin from '../../mixins/number-format-mixins';

export default {
  name: 'FTClassificationComponent',
  props: { provider: {}, program: {}, year: {} },
  mixins: [NumberFormatMixin],
  computed: {
    classifications() {
      const classificationList = this.$store.getters
        .classifications(this.provider, this.program, this.year);
      classificationList.forEach((classification) => {
        const c = classification;
        c.PercentageEmployedTeachers = this.percentOrBlank(
          classification.PercentageEmployedTeachers,
        );
        c.PercentageEmployedTeachersState = this.percentOrBlank(
          classification.PercentageEmployedTeachersState,
        );
      });
      return classificationList;
    },
    headersClassification() {
      return [
        { text: 'Category', value: 'SchoolClassification' },
        { text: `Employed as of October 15, ${this.currentYearStart()}`, value: 'EmployedasofOct15' },
        { text: 'Percent Employed as Teachers', value: 'PercentageEmployedTeachers' },
        { text: 'Percentage Employed Statewide', value: 'PercentageEmployedTeachersState' },
      ];
    },
  },
  methods: {
    addFootnote(value) {
      if (value === 'Comprehensive Support and Improvement') {
        return 'Comprehensive Support and Improvement<sup>8</sup>';
      }
      if (value === 'Targeted Support and Improvement') {
        return 'Targeted Support and Improvement<sup>9</sup>';
      }
      return value;
    },
    currentYearStart() {
      return this.year - 1;
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
  .percent:after{
      content: "%";
  }
</style>
