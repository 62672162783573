<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <v-card style="width: 100%">
      <v-card-title class="headline">Content Assessment</v-card-title>
      <v-card-text>
          <h4>Praxis II<v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on"><sup>5</sup></span>
            </template>
              <span class="tooltip-text">PRAXIS II is a content assessment required for
                 certification. Future report iterations will includedata on additional
                  assessments required for certification and survey results from programcompleters
                   and employers. For information about expired assessments, see the glossary at
                    the endof this report. This data is suppressed if the count of valid scores is
                     less than 10.
            </span></v-tooltip>
            Results:<span v-if="this.year < 2022"> Average Scaled Score</span>
          </h4>
          <template>
            <v-data-table
                :headers="headersArea"
                :items="praxisData"
                class="elevation-1"
                disable-pagination :hide-default-footer="true"
              >
              <template v-for="header in headersArea"
              v-slot:[`header.${header.value}`]="{ header }"
              >
                <span tabindex="0" v-html="header.text"></span>
              </template>
            </v-data-table>
              <!-- <v-data-table
                      :headers="headersArea"
                      :items="praxisData"
                      disable-pagination :hide-default-footer="true"
                      class="elevation-1"
              >
                  <template slot="headerCell" slot-scope="props">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span class="table-header"  v-on="on">
                            {{ props.header.text }}
                          </span>
                        </template>
                          <span>
                                    {{ props.header.text }}
                                  </span>
                      </v-tooltip>
                  </template>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td v-html="props.item.TestName"></td>
                      <td v-html="props.item.AvgScaledScore" class="text-xs-center"></td>
                      <td v-html="props.item.CutScore" class="text-xs-center"></td>
                      <td v-html="props.item.StateAvgScaledScore" class="text-xs-center"></td>
                    </tr>
                  </template>
              </v-data-table> -->
          </template>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CAContentPrintThreeComponent',
  props: { provider: {}, program: {}, year: {} },
  computed: {
    praxis() {
      return this.$store.getters.praxis(this.provider, this.program, this.year);
    },
    praxisData() {
      const p = this.praxis;
      return p.slice(44, 66);
    },
  },
  data() {
    if (this.year >= 2022) {
      return {
        headersArea: [
          { text: 'Praxis II Test', value: 'TestName' },
          { text: this.year >= 2020 ? 'Average Score for Certified Completers' : 'EPP Average Scaled Score', value: 'AvgScaledScore' },
          { text: 'Test Cut Score', value: 'CutScore' },
          { text: 'State Average Scaled Score', value: 'StateAvgScaledScore' },
          { text: 'State First-Time Pass Rate<sup>7</sup>', value: 'StateFirstTimePassRate' },
          { text: 'State Overall Pass Rate<sup>8</sup>', value: 'StateOverallPassRate' },
          { text: 'Provider First-Time Pass Rate<sup>7</sup>', value: 'FirstTimePassRate' },
          { text: 'Provider Overall Pass Rate<sup>8</sup>', value: 'OverallPassRate' },
        ],
      };
    }

    return {
      headersArea: [
        { text: 'Praxis II Test', value: 'TestName' },
        { text: this.year >= 2020 ? 'Average Score for Certified Completers' : 'EPP Average Scaled Score', value: 'AvgScaledScore' },
        { text: 'Test Cut Score', value: 'CutScore' },
        { text: 'State Average Scaled Score', value: 'StateAvgScaledScore' },
      ],
    };
  },
};
</script>

<style scoped>
  .v-card__title{
      padding: 0 16px;
      color: #60521B;
  }
  .v-card__text{
      padding: 0 16px;
  }
  .v-card{
      box-shadow: none;
  }
  .margin-top{
      margin-top: 20px;
  }
  h4{
      font-weight: normal;
      font-size: 1.2rem;
      color: #60521B;
  }
  .tooltip-text{
      font-size: 1rem;
  }
</style>
