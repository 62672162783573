<template>
  <!--<v-app name="ProviderSelect">-->
    <div>
    <h4>New Jersey Performance Reports for Educator Preparation</h4>
    <p>
      The New Jersey Department of Education has released the {{ reportName }} to provide
      transparent data on teacher preparation providers and outcomes. These reports include
      information on newly certified Certificate of Eligibility (CE) and Certificate
      of Eligibility with Advanced Standing (CEAS) teachers' certification and  hiring, placement,
      classroom assignment, demographics, education program, and  testing results. Each report
      reflects information for a specific cohort.&nbsp;  The cohort reporting dates are September
      1 to August 31. For more information please reference the
      <a
        href="https://www.state.nj.us/education/educators/rpr/preparation/providers/2015/overview.pdf">
        {{ reportName }}  Overview
      </a>.
    </p>
    <div class="form-wrapper">
      <div class="select-wrapper">
        <div class="select">
          <select v-model="year" id="slctYear" @change="selectNewYear">
            <option value="" selected="selected">Choose a Year</option>
            <option v-for="y in years" :key="y.Year" :value="y.Year">{{ y.Year }}</option>
          </select>
        </div>
        <div class="select" v-if="year >= 2018">
          <select v-model="provider" id="slctProvider" @change="selectNewProvider">
            <option value="" selected="selected">Choose a Provider</option>
            <option v-for="p in providers"
              :key="p.ProviderName"
              :value="p.ProviderName">
              {{ p.ProviderName }}
            </option>
          </select>
        </div>
        <div class="select" v-if="provider !== '' && year >= 2018 && !hasOnlyAllPrograms">
          <select v-model="program" id="slctProvider">
            <option value="" selected="selected">Choose a Program</option>
            <option v-for="p in programs"
              :key="p.ProgramName"
              :value="p.ProgramName">
              {{ p.ProgramName }}
            </option>
          </select>
        </div>
      </div>
      <div class="button-wrapper">
        <div class="button3" v-if="program === '' && year >= 2018 && !hasOnlyAllPrograms">
          View Report Online
        </div>
        <div class="button3" v-if="program === '' && year >= 2018 && !hasOnlyAllPrograms"
          target="_blank">
          <i class="fas fa-file-pdf"></i>  Download PDF Report
        </div>

        <a href="https://www.nj.gov/education/rpi/preparation/providers/2017providers.shtml"
          class="button2" v-if="year === 2017" target="_blank">
          <i class="fas fa-file-pdf"></i>  View 2017 PDF Reports
        </a>
        <a href="https://www.nj.gov/education/rpi/preparation/providers/2016providers.shtml"
          class="button2" v-if="year === 2016" target="_blank">
          <i class="fas fa-file-pdf"></i>  View 2016 PDF Reports
        </a>
        <a href="https://www.nj.gov/education/rpi/preparation/providers/2015providers.shtml"
          class="button2" v-if="year === 2015" target="_blank">
          <i class="fas fa-file-pdf"></i>  View 2015 PDF Reports
        </a>
        <a href="https://www.nj.gov/education/rpi/preparation/providers/2014providers.shtml"
          class="button2" v-if="year === 2014" target="_blank">
          <i class="fas fa-file-pdf"></i>  View 2014 PDF Reports
        </a>

        <router-link :to="'/home/' + year + '/' + provider + '/' + 'All Programs'"
          class="button" v-if="hasOnlyAllPrograms && year >= 2018">View Report Online</router-link>
        <router-link :to="'/home/' + year + '/' + provider + '/' + program"
          class="button" v-else-if="program !== '' && year >= 2018">View Report Online</router-link>

        <router-link :to="'/report-pdf/' + year + '/' + provider + '-' + 'All Programs' + '.pdf'"
          class="button" v-if="hasOnlyAllPrograms && year >= 2018"  target="_blank">
          <i class="fas fa-file-pdf"></i>  Download PDF Report
        </router-link>
        <router-link :to="'/report-pdf/' + year + '/' + provider + '-' + pdfProgramName + '.pdf'"
          class="button" v-else-if="program !== '' && year >= 2018"  target="_blank">
          <i class="fas fa-file-pdf"></i>  Download PDF Report
        </router-link>

      </div>
    </div>
  <!--</v-app>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: '',
      provider: '',
      program: '',
    };
  },
  computed: {
    reportName() {
      return 'Performance Reports for Educator Preparation';
    },
    years() {
      return this.$store.getters.year;
    },
    providers() {
      const list = {};
      this.$store.getters.provider(this.year).forEach((x) => {
        if (typeof list[x.ProviderName] === 'undefined') {
          list[x.ProviderName] = { ProviderName: x.ProviderName };
        }
      });
      return Object.values(list);
    },
    programs() {
      let programs = this.$store.getters.programs(this.provider, this.year);
      if (process.env.VUE_APP_SHOW_ONLY_ALL_PROGRAMS === 'YES') {
        programs = programs.filter((obj) => obj.ProgramName === 'All Programs');
      }
      return programs;
    },
    hasOnlyAllPrograms() {
      let programs = this.$store.getters.programs(this.provider, this.year);
      if (process.env.VUE_APP_SHOW_ONLY_ALL_PROGRAMS === 'YES') {
        programs = programs.filter((obj) => obj.ProgramName === 'All Programs');
      }
      return programs.length === 1 && programs[0].ProgramName === 'All Programs';
    },
    pdfProgramName() {
      return this.program.replace(':', '');
    },
  },
  methods: {
    selectNewYear() {
      this.provider = '';
      this.program = '';
    },
    selectNewProvider() {
      this.program = '';
    },
  },
};
</script>

<style scoped>
.button{
  background-color: #2c4677;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}
.button2{
  background-color: #2c4677;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 50px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}
.button3{
  background-color: #aeaeae;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: default;
}
.button:hover{
  background-color: #008CBA;
  color: white;
}
.button2:hover{
  background-color: #008CBA;
  color: white;
}
.button-wrapper{
  padding-left: 13%;
}
.form-wrapper{
  padding: 0 5%;
}
div { margin: 20px; }

.fas {
  font-size: 1.5rem;
  float: left;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #008CBA;
  background-image: none;
}
/* Custom Select */
.select {
  position: relative;
  display: block;
  width: 89%;
  height: 4em;
  line-height: 3;
  background: #008CBA;
  overflow: hidden;
  /*border-radius: .25em;*/
}
select {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 0 1.5em;
  color: #fff;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
}
select::-ms-expand {
  display: none;
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 7px 1em;
  background: #2c4677;
  pointer-events: none;
  color: #ffffff;
}
/* Transition */
.select:hover::after {
  color: #008CBA;
}
.select::after {
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
</style>
