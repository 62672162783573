<template>
<v-container fluid >
    <v-row>
      <v-col cols="12"  style="">
      <div class="pdf-footnotes footnotes">
          <v-card-text  v-for="item in items" :key="item.id"
           style="padding-bottom: 2px; padding-top: 2px;">
              <span v-html="item.note"></span>
          </v-card-text>
          <div class="page-number-wrapper"><span class="page-number">Page </span></div>

      </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FootnotesComponent',
  props: { items: {}, pageNumber: String },
  mounted() {
    console.log('Footnotes Mounted');
  },
};
</script>

<style>
.footnotes{
  background: #dcdcdc;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-top: 10px;
}

</style>
