<template v-slot:activator="{ on }">
  <div>
  <v-card-title class="mission-headline headline" style="color: #A5121B;">
    Mission <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on"><sup>1a</sup></span>
      </template>
      <span class="tooltip-text">The mission statement was provided to the
         NJDOE by the institution through the application
        process to become an approved teacher preparation provider.</span>
      </v-tooltip>:</v-card-title>
  <v-card-text>
      <p class="mission-text">{{ missionData.Mission }}</p>
  </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'PPMissionComponent',
  props: { provider: {}, year: {} },
  computed: {
    missionData() {
      return this.$store.getters.mission(this.provider, this.year);
    },
  },
};
</script>

<style scoped>
  .tooltip-text{
      font-size: 1rem;
  }
  p.mission-text {
      color: #595959;
  }
</style>
