<template>
  <div>
  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
  <v-row no-gutters dense>
    <v-col>
      <v-card  flat>
          <v-card-title class="financial-aid-headline"
           v-if="!(this.year >= 2022 && isCeCeas)">
            <div style="white-space:pre-wrap;  word-break:break-word;">
              <span v-if="!isCeCeas">This Provider has&nbsp;<strong style="color: #e5212f;">
                {{ NumberOfPartnerships.NumberOfPartnerships }}</strong>&nbsp;Partnerships</span>
              <span v-if="isCeCeas">These providers have partnerships</span>
              <v-tooltip bottom style="padding-right: 3px;">
                <template v-slot:activator="{ on }">
                <span v-on="on"><sup>2</sup>&nbsp;</span>
              </template>
                <span class="tooltip-text">A partnership is a formal or informal agreement with a
                  school or district where candidates can complete program requirements.
                    Partnerships are provided by institution.</span>
              </v-tooltip>
              <span>with NJ schools or districts and {{ isCeCeas ? 'are' : 'is' }} accredited</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on"><sup>3</sup></span>
                </template>
                <span class="tooltip-text">Teacher preparation programs are accredited at
                  least every 7 years.</span>
              </v-tooltip>
              <span v-if="this.year >= 2022">.</span>
              <span v-else>
                &nbsp;through the Council for the Accreditation of Educator Preparation.</span>
            </div>
          </v-card-title>
          <v-card-text>
              <v-card-title class="pp-fin-head">
                <router-link
                v-if="!disableLinks"
                  :to="financialAidGlossary()">
                  Financial Aid<v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span slot="activator" v-on="on"><sup>4</sup></span>
                    </template>
                    <span class="tooltip-text">More information about financial aid options
                       is available in the glossary at the end of this report.</span>
                  </v-tooltip>&nbsp;Options:
                </router-link>
                <h4 v-else>
                  Financial Aid&nbsp;Options:
                </h4>
              </v-card-title>
              <v-list dense>
                <v-list-item
                  v-for="item in list"
                  :key="item.title" class="pp-fin-title" >
                  <v-list-item-icon  v-if="!isCeCeas"
                   style="padding-right:15px;margin-top:0px; margin-bottom:0px">
                    <v-icon aria-label="Checked" alt="Checked" v-if="hasAid(item.title)"
                           color="green">fa-check</v-icon>
                    <v-icon aria-label="Not Checked" alt="Not Checked" v-else color="red">
                      fa-times-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pp-fin-item-cont">
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-content>
                    <div v-if="hasAid(item.title)">
                      <p class="offscreen">Green Check </p>
                      <span>{{ item.title }}</span>
                    </div>
                    <div v-else>
                        <p class="offscreen">Red X </p>
                        <span>{{ item.title }}</span>
                    </div>
                  </v-list-item-content>-->
                </v-list-item>
                <!-- <v-list-tile
                          v-for="item in list"
                          :key="item.title"
                          avatar
                          class="aid-list"
                  >
                      <v-list-tile-action v-if="!isCeCeas">
                          <v-icon aria-label="Checked" alt="Checked" v-if="hasAid(item.title)"
                           color="green">fa-check</v-icon>
                          <v-icon aria-label="Not Checked" alt="Not Checked" v-else color="red">
                            fa-times-circle</v-icon>
                      </v-list-tile-action>

                      <v-list-tile-content>
                          <div v-if="hasAid(item.title)">
                              <p class="offscreen">Green Check </p>
                              <span>{{ item.title }}</span>
                          </div>
                          <div v-else>
                              <p class="offscreen">Red X </p>
                              <span>{{ item.title }}</span>
                          </div>
                      </v-list-tile-content>
                  </v-list-tile> -->
                  <!-- <v-list-tile avatar class="aid-list">
                    <v-list-tile-action v-if="!isCeCeas">
                      <v-icon aria-label="Checked" alt="Checked" v-if="providedData()"
                       color="green">fa-check</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                          <div v-if="providedData()">
                              <p class="offscreen">Green Check </p>
                              <span>Data Not Provided</span>
                          </div>
                          <div v-else>
                              <p class="offscreen">Red X </p>
                              <span>Data Not Provided</span>
                          </div>
                    </v-list-tile-content>
                  </v-list-tile> -->
                  <!-- <v-list-tile
                          v-for="item in list"
                          :key="item.title"
                          avatar
                          class="aid-list"
                  >
                      <v-list-tile-action v-if="!isCeCeas">
                          <v-icon aria-label="Checked" alt="Checked" v-if="hasAid(item.title)"
                           color="green">fa-check</v-icon>
                          <v-icon aria-label="Not Checked" alt="Not Checked" v-else color="red">
                            fa-times-circle</v-icon>
                      </v-list-tile-action>

                      <v-list-tile-content>
                          <div v-if="hasAid(item.title)">
                              <p class="offscreen">Green Check </p>
                              <span>{{ item.title }}</span>
                          </div>
                          <div v-else>
                              <p class="offscreen">Red X </p>
                              <span>{{ item.title }}</span>
                          </div>
                      </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile avatar class="aid-list">
                    <v-list-tile-action v-if="!isCeCeas">
                      <v-icon aria-label="Checked" alt="Checked" v-if="providedData()"
                       color="green">fa-check</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                          <div v-if="providedData()">
                              <p class="offscreen">Green Check </p>
                              <span>Data Not Provided</span>
                          </div>
                          <div v-else>
                              <p class="offscreen">Red X </p>
                              <span>Data Not Provided</span>
                          </div>
                    </v-list-tile-content>
                  </v-list-tile> -->
              </v-list>
          </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</div>
</template>

<script>
export default {
  name: 'PPFinancialAidComponent',
  props: { provider: {}, year: {}, disableLinks: {} },
  computed: {
    financialAid() {
      return this.$store.getters.financialAid(this.provider, this.year);
    },
    NumberOfPartnerships() {
      return this.$store.getters.mission(this.provider, this.year);
    },
    isCeCeas() {
      if (this.year >= 2020) {
        const ce = 'NJ CE Providers';
        const ceas = 'NJ CEAS Providers';
        return this.provider === ce || this.provider === ceas;
      }
      return false;
    },
    list() {
      if (this.year >= 2020) {
        return [
          { icon: false, title: 'AmeriCorps' },
          { icon: false, title: 'Federal Direct Loans' },
          { icon: false, title: 'Federal PELL Grants' },
          { icon: false, title: 'Federal Perkins' },
          { icon: false, title: 'Federal Work Study' },
          { icon: false, title: 'Law Enforcement Memorial Scholarship' },
          { icon: false, title: 'NJ Class Loans' },
          { icon: false, title: 'NJ Educational Opportunity Fund' },
          { icon: false, title: 'NJ Governors Industry' },
          { icon: false, title: 'NJ Governors Urban Scholarship' },
          { icon: false, title: 'NJ STARS' },
          { icon: false, title: 'NJ Survivor Tuition Benefits' },
          { icon: false, title: 'NJ Tuition Aid Grant (TAG)' },
          { icon: false, title: 'NJ WTC' },
          { icon: false, title: 'TEACH Grant' },
          { icon: false, title: 'Title IV Financial Aid' },
          { icon: false, title: 'Other' },
        ];
      }
      return [
        { icon: false, title: 'AmeriCorps' },
        { icon: false, title: 'Federal Direct Loans' },
        { icon: false, title: 'Federal PELL Grants' },
        { icon: false, title: 'Federal Perkins' },
        { icon: false, title: 'Federal Work Study' },
        { icon: false, title: 'Law Enforcement Memorial Scholarship' },
        { icon: false, title: 'NJ Class Loans' },
        { icon: false, title: 'NJ Educational Opportunity Fund' },
        { icon: false, title: 'NJ Governors Industry' },
        { icon: false, title: 'NJ Governors Urban Scholarship' },
        { icon: false, title: 'NJ STARS' },
        { icon: false, title: 'NJ Survivor Tuition Benefits' },
        { icon: false, title: 'NJ Tuition Aid Grant (TAG)' },
        { icon: false, title: 'NJ WTC' },
        { icon: false, title: 'Teach Grant' },
        { icon: false, title: 'Title IV Financial Aid' },
        { icon: false, title: 'Other' },
      ];
    },
  },
  methods: {
    hasAid(title) {
      return this.financialAid.find((e) => e.toUpperCase().trim() === title.toUpperCase() || (e === 'NA' && title === 'Data Not Provided') || (e.trim() === 'All' && title !== 'Data Not Provided'));
    },
    providedData() {
      return this.financialAid.find((e) => e === 'NA');
    },
    financialAidGlossary() {
      return {
        name: 'Glossary',
        params: {
          year: this.$route.params.year,
          provider: this.$route.params.provider,
          program: this.$route.params.program,
        },
      };
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.pp-fin-head{
  color: #2C7FC3;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
}
.pp-fin-item-cont{
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 0.9rem;
  font-weight: normal!important;;
}
.pp-fin-item-cont .v-list-item__title{
  font-size: 0.9rem;
  font-weight: normal!important;;
}
.pp-fin-title{
  min-height: 22px;
  font-weight: normal!important;;
}
.financial-aid-headline{
  color: #595959;
  font-size: 1rem;
}

  .aid-list{
      height: 51px;
  }
.offscreen{
  position: absolute;
  left: -100000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
</style>
